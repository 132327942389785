import { useEffect, useState } from "react";


export function useIsEpochTimeInPast(timeMs: number): boolean {
    const [inPast, setInPast] = useState<boolean>(Date.now() >= timeMs);

    useEffect(() => {
        let cancelled = false;
        let timeoutID: number | NodeJS.Timeout | null = null;

        const checkTime = () => {
            if (cancelled)
                return;

            const msUntilTime = timeMs - Date.now();
            setInPast(msUntilTime <= 0);
            if (msUntilTime <= 0)
                return;

            timeoutID = setTimeout(checkTime, 50 + msUntilTime);
        };

        checkTime();
        return () => {
            cancelled = true;
            if (timeoutID !== null) {
                clearTimeout(timeoutID);
            }
        };
    }, [inPast, timeMs]);

    return inPast;
}
