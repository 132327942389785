import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { LobbyPlayerType } from "@/ts/business/lobby/LobbyPlayerType";
import { APIPublicGamePreferences, APIUser } from "@/ts/business/api/api_schema";
import { BotType } from "@/ts/business/game/BotType";


export class UnknownLobbyPlayer extends LobbyPlayer {
    constructor(gameData: GamePlayerData | null) {
        super(LobbyPlayerType.UNKNOWN, gameData);
    }

    override getBotTypeOrNull(): BotType | null {
        return null;
    }

    override getUserOrNull(): APIUser | null {
        return null;
    }

    override isAnonymous(): boolean {
        return false;
    }

    override getShortName(): string {
        return "Unknown";
    }

    override withPreferences(_preferences: APIPublicGamePreferences): LobbyPlayer {
        // Unknown players don't have preferences.
        return this;
    }

    override withGameData(gameData: GamePlayerData): LobbyPlayer {
        return new UnknownLobbyPlayer(
            gameData,
        );
    }

    override equals(other: LobbyPlayer): boolean {
        if (!LobbyPlayer._baseEquals(this, other))
            return false;

        return other instanceof UnknownLobbyPlayer;
    }
}
