

export class Key {
    public static readonly SPACE = new Key([" ", "Space"]);
    public static readonly ESCAPE = new Key(["Escape", "Esc"]);
    public static readonly ENTER = new Key(["Enter"]);
    public static readonly Q = new Key(["q", "KeyQ"]);
    public static readonly ARROW_LEFT = new Key(["ArrowLeft"]);
    public static readonly ARROW_RIGHT = new Key(["ArrowRight"]);
    public static readonly ARROW_UP = new Key(["ArrowUp"]);
    public static readonly ARROW_DOWN = new Key(["ArrowDown"]);

    private readonly identifiers: string[];

    constructor(identifiers: string[]) {
        this.identifiers = identifiers;
    }

    isKey(identifier: string): boolean {
        for (const potentialIdentifier of this.identifiers) {
            if (identifier === potentialIdentifier)
                return true;
        }
        return false;
    }
}


export function registerKeyListener(listener: (event: KeyboardEvent) => void): () => void {
    const keyListener = (event: KeyboardEvent) => {
        if (!event.defaultPrevented) {
            listener(event);
        }
    };
    document.addEventListener("keydown", keyListener);
    return () => {
        document.removeEventListener("keydown", keyListener);
    };
}
