import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { MoveBoardDirective } from "@/ts/business/game/controller/board/MoveBoardDirective";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


export interface ReplayMoveProperties {
    wait: number;
    speed: number;
}


/**
 * A directive for rendering the dice.
 */
export class ReplayMovesBoardDirective extends BoardDirective {
    readonly moves: MoveBoardDirective[];
    readonly moveProperties?: ReplayMoveProperties[];

    constructor(
        directiveID: number,
        rules: RuleSet,
        moves: MoveBoardDirective[],
        moveProperties?: ReplayMoveProperties[],
    ) {
        super(directiveID, rules, moves[0].moveIndex);
        this.moves = moves;
        this.moveProperties = moveProperties;
    }

    override isLimboDirective(): boolean {
        return false;
    }

    override getBoard(): Board {
        throw new Error("Replay moves board directive does not have a board");
    }

    override getActivePlayer(): PlayerType {
        throw new Error("Replay moves board directive does not have an active player");
    }
}
