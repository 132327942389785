import { Directive } from "@/ts/business/game/controller/Directive";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A directive for rendering the state of the player.
 */
export abstract class PlayerStateDirective extends Directive {
    readonly moveIndex: number;

    protected constructor(directiveID: number, moveIndex: number) {
        super(directiveID);
        this.moveIndex = moveIndex;
    }

    override isLimboDirective(): boolean {
        return true;
    }

    abstract getActivePlayer(moveIndex: number): PlayerType;

    abstract getLightPlayerState(moveIndex: number): PlayerState;

    abstract getDarkPlayerState(moveIndex: number): PlayerState;

    getPlayerState(player: PlayerType, moveIndex: number): PlayerState {
        switch (player) {
            case PlayerType.LIGHT:
                return this.getLightPlayerState(moveIndex);
            case PlayerType.DARK:
                return this.getDarkPlayerState(moveIndex);
            default:
                throw new Error("Unknown player type " + JSON.stringify(player));
        }
    }
}
