import { Board } from "@/ts/royalur/model/Board";
import { Move } from "@/ts/royalur/model/Move";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";


/**
 * A directive for rendering the dice.
 */
export class PlayBoardDirective extends BoardDirective {
    readonly activePlayer: PlayerType;
    readonly board: Board;
    readonly roll: RoyalUrRoll | null;
    readonly availableMoves: Move[] | null;
    readonly isGameWon: boolean;

    constructor(
        directiveID: number,
        rules: RuleSet,
        moveIndex: number,
        activePlayer: PlayerType,
        board: Board,
        roll: RoyalUrRoll | null,
        availableMoves: Move[] | null,
        isGameWon: boolean,
    ) {
        super(directiveID, rules, moveIndex);
        this.board = board;
        this.roll = roll;
        this.availableMoves = availableMoves;
        this.isGameWon = isGameWon;
        this.activePlayer = activePlayer;
    }

    isLimboDirective(): boolean {
        return true;
    }

    override getBoard(): Board {
        return this.board;
    }

    override getActivePlayer(): PlayerType {
        return this.activePlayer;
    }
}
