import { ListenerStore } from "@/ts/business/ListenerStore";
import { BotType } from "@/ts/business/game/BotType";
import { GameType } from "@/ts/business/game/GameType";
import { GameMode } from "@/ts/business/game/GameMode";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { GameSettings } from "@/ts/royalur/model/GameSettings";


export type RemoteSettingsListener = (resolvedSettings: LobbySettings) => void;


/**
 * Used for game settings where the game settings need to be retrieved
 * from a remote server.
 */
export class RemoteLobbySettings extends LobbySettings {
    private readonly resolveListeners: ListenerStore<RemoteSettingsListener>;

    constructor(lobbyID: string) {
        super(false, lobbyID);
        this.resolveListeners = new ListenerStore<RemoteSettingsListener>();
    }

    addResolveListener(listener: RemoteSettingsListener): () => void {
        return this.resolveListeners.add(listener);
    }

    override getLobbyID(): string {
        const lobbyID = super.getLobbyID();
        if (lobbyID === null)
            throw new Error("Missing lobby ID, but there should definitely be a lobby ID!");

        return lobbyID;
    }

    override getMode(): GameMode {
        throw new Error("Game mode is not yet available");
    }

    override getGameType(): GameType {
        throw new Error("Game type is not yet available");
    }

    override getGameSettings(): GameSettings {
        throw new Error("Game settings are not yet available");
    }

    override getCustomGameSettings(): GameSettings {
        throw new Error("Custom game settings are not yet available");
    }

    override getBotType(): BotType {
        throw new Error("Bot type is not yet available");
    }

    override toJSON(): string {
        throw new Error("Settings are not yet available");
    }

    override equals(other: LobbySettings): boolean {
        if (!(other instanceof RemoteLobbySettings))
            return false;

        return this.getLobbyID() === other.getLobbyID();
    }
}
