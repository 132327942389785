import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { AnalyticsProvider } from "@/ts/business/analytics/Analytics";
import { MessageOutUploadGame } from "@/ts/business/api/game/MessageOutUploadGame";
import { GameAPI } from "@/ts/business/api/GameAPI";
import { APIGameAnalysisOverview, APIGamePreferences } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { Game } from "@/ts/royalur/Game";
import { AnalysisLobbyStatus } from "@/ts/business/game/controller/status/AnalysisLobbyStatus";
import { Optional } from "@/ts/util/Optional";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class AnalysisGameSource extends GameSource<AnalysisLobbyStatus> {
    constructor(
        initialClientControls: GameClientControls,
        initialPreferences: APIGamePreferences,
        analytics: AnalyticsProvider,
        gameAPI: GameAPI,
        settings: KnownLobbySettings,
        game: Game,
        players: GamePlayers,
        endReason: GameEndReason,
        endingPlayer: PlayerType | null,
        completeGameID: string,
        analysisOverview: APIGameAnalysisOverview | null,
    ) {
        super(
            initialClientControls, initialPreferences, analytics, gameAPI, settings,
            new AnalysisLobbyStatus(
                LobbyID.ofCompleteGame(completeGameID),
                settings, endReason, endingPlayer, completeGameID,
            ),
            game.getRules(),
        );
        this.game.set(game, new GameUpdateEvent(false));
        this.analysisOverview.set(Optional.ofNullable(analysisOverview));
        this.players.set(players);
    }

    override getControllerMaxDirectives(): number {
        return 0;
    }

    override isLocalHumanPlayer(_playerType: PlayerType): boolean {
        return false;
    }

    override setup(): () => void {
        return () => {};
    }

    override handleResign(): void {
        throw new Error("AnalysisGameSource does not support handleResign");
    }

    override handleMove(): void {
        throw new Error("AnalysisGameSource does not support handleMove");
    }

    override handleRoll(_event: RollGameEvent): void {
        throw new Error("AnalysisGameSource does not support handleRoll");
    }

    override handleReaction(_player: PlayerType | null, _reaction: ReactionType) {
        throw new Error("AnalysisGameSource does not support handleReaction");
    }

    override shouldUploadGame(): boolean {
        return false;
    }

    override buildUploadGameMessage(): MessageOutUploadGame {
        throw new Error("AnalysisGameSource does not support buildUploadGameMessage");
    }
}
