import {
    ControllerProviderType,
    createControllerContext,
    useCreateController,
} from "@/app_components/game/createControllerContext";
import { ReactionController } from "@/ts/business/game/controller/reactions/ReactionController";
import { ReactionDirective } from "@/ts/business/game/controller/reactions/ReactionDirective";


function createReactionControllerContext(): {
    ReactionControllerProvider: ControllerProviderType;
    useReactionController: () => ReactionController;
    useReactionDirective: () => ReactionDirective;
    useReactionDirectiveSelector: <V>(selector: (directive: ReactionDirective) => V) => V;
} {
    const useCreateReactionController = () => {
        return useCreateController<ReactionDirective, ReactionController>(
            () => new ReactionController(), [],
        );
    };
    const {
        ControllerProvider, useController, useDirective, useDirectiveSelector,
    } = createControllerContext<ReactionDirective, ReactionController>(useCreateReactionController);

    return {
        ReactionControllerProvider: ControllerProvider,
        useReactionController: useController,
        useReactionDirective: useDirective,
        useReactionDirectiveSelector: useDirectiveSelector,
    };
}

export const {
    ReactionControllerProvider,
    useReactionController,
    useReactionDirective,
} = createReactionControllerContext();
