import { Controller } from "@/ts/business/game/controller/Controller";
import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerStateDirective } from "./PlayerStateDirective";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BasicPlayerStateDirective } from "@/ts/business/game/controller/playerstate/BasicPlayerStateDirective";
import { ReplayPlayerStatesDirective } from "@/ts/business/game/controller/playerstate/ReplayPlayerStatesDirective";


export interface ReplayPlayerState {
    moveIndex: number;
    lightPlayerState: PlayerState;
    darkPlayerState: PlayerState;
    activePlayer: PlayerType;
}


/**
 * Controls the rendering directives to give to the dice.
 */
export class PlayerStateController extends Controller<PlayerStateDirective> {
    override setup(): () => void {
        return () => {};
    }

    override onDiscardedDirectives(_directives: PlayerStateDirective[]): void {
        // Nothing to do.
    }

    createBasic(
        moveIndex: number,
        lightPlayerState: PlayerState,
        darkPlayerState: PlayerState,
        activePlayer: PlayerType,
    ): PlayerStateDirective[] {

        return [new BasicPlayerStateDirective(
            this.assignNextDirectiveID(),
            moveIndex,
            lightPlayerState,
            darkPlayerState,
            activePlayer,
        )];
    }

    createReplay(
        states: ReplayPlayerState[],
    ): PlayerStateDirective[] {
        if (states.length <= 0)
            throw new Error("Requires at least one state");

        const stateDirectives: PlayerStateDirective[] = [];
        for (let index = 0; index < states.length; ++index) {
            const {
                moveIndex,
                lightPlayerState,
                darkPlayerState,
                activePlayer,
            } = states[index];

            stateDirectives.push(new BasicPlayerStateDirective(
                this.assignNextDirectiveID(),
                moveIndex,
                lightPlayerState,
                darkPlayerState,
                activePlayer,
            ));
        }

        return [
            new ReplayPlayerStatesDirective(
                this.assignNextDirectiveID(),
                stateDirectives,
            ),
        ];
    }
}
