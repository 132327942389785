

export class GameThemeType {
    public static readonly DEFAULT = new GameThemeType(
        "Default", null,
    );

    public static readonly HOLIDAY = new GameThemeType(
        "Holiday",
        date => [ // December 18th - December 31st
            new Date(date.getFullYear(), 11, 18),
            new Date(date.getFullYear(), 11, 31, 23, 59, 59, 999),
        ]
    );

    public static readonly HALLOWEEN = new GameThemeType(
        "Halloween",
        date => [ // October 25th - November 6th
            new Date(date.getFullYear(), 9, 25),
            new Date(date.getFullYear(), 10, 6, 23, 59, 59, 999),
        ]
    );

    static values(): GameThemeType[] {
        return [
            GameThemeType.DEFAULT,
            GameThemeType.HOLIDAY,
            GameThemeType.HALLOWEEN,
        ];
    }

    private readonly name: string;
    private readonly dateRangeGenerator: ((date: Date) => [Date, Date]) | null;

    constructor(name: string, dateRangeGenerator: ((date: Date) => [Date, Date]) | null) {
        this.name = name;
        this.dateRangeGenerator = dateRangeGenerator;
    }

    getName(): string {
        return this.name;
    }

    hasDateRange(): boolean {
        return this.dateRangeGenerator !== null;
    }

    isInDateRange(date: Date): boolean {
        if (this.dateRangeGenerator === null)
            return false;

        const [start, end] = this.dateRangeGenerator(date);
        return date >= start && date <= end;
    }

    static getByName(name: string): GameThemeType {
        for (const themeType of GameThemeType.values()) {
            if (themeType.name === name)
                return themeType;
        }
        throw new Error("Unknown game theme type: " + name);
    }

    static getByNameOrNull(name: string | null): GameThemeType | null {
        if (name === null)
            return null;

        for (const themeType of GameThemeType.values()) {
            if (themeType.name === name)
                return themeType;
        }
        return null;
    }
}
