import {
    ControllerProviderType,
    createControllerContext,
    useCreateController,
} from "@/app_components/game/createControllerContext";
import { BoardController } from "@/ts/business/game/controller/board/BoardController";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";


function createBoardControllerContext(): {
    BoardControllerProvider: ControllerProviderType;
    useBoardController: () => BoardController;
    useBoardDirective: () => BoardDirective;
} {
    const useCreateBoardController = () => {
        return useCreateController<BoardDirective, BoardController>(
            () => new BoardController(), [],
        );
    };
    const {
        ControllerProvider, useController, useDirective,
    } = createControllerContext<BoardDirective, BoardController>(useCreateBoardController);

    return {
        BoardControllerProvider: ControllerProvider,
        useBoardController: useController,
        useBoardDirective: useDirective,
    };
}

export const {
    BoardControllerProvider, useBoardController, useBoardDirective,
} = createBoardControllerContext();
