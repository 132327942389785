import { Game } from "@/ts/royalur/Game";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";


/**
 * Finds the change from one game to update it to the new game.
 */
export class GameDiff {
    private readonly newGame: Game;
    private readonly previousGame: Game;

    private readonly newStates: GameState[];
    private readonly previousStates: GameState[];
    private readonly previousContainsAction: boolean;

    private readonly commonIndexNew: number;
    private readonly commonIndexPrevious: number;

    constructor(
        newGame: Game,
        previousGame: Game,
        newStates: GameState[],
        previousStates: GameState[],
        previousContainsAction: boolean,
        commonIndexNew: number,
        commonIndexPrevious: number,
    ) {
        if ((commonIndexNew < 0) !== (commonIndexPrevious < 0))
            throw new Error("Inconsistent common index for new and previous game");

        this.newGame = newGame;
        this.previousGame = previousGame;
        this.previousContainsAction = previousContainsAction;
        this.newStates = newStates;
        this.previousStates = previousStates;
        this.commonIndexNew = (commonIndexNew < 0 ? -1 : commonIndexNew);
        this.commonIndexPrevious = (commonIndexPrevious < 0 ? -1 : commonIndexPrevious);
    }

    getNewGame(): Game {
        return this.newGame;
    }

    getPreviousGame(): Game {
        return this.previousGame;
    }

    getNewStates(): GameState[] {
        return this.newStates;
    }

    getPreviousStates(): GameState[] {
        return this.previousStates;
    }

    doesPreviousContainAction(): boolean {
        return this.previousContainsAction;
    }

    hasCommonIndex(): boolean {
        return this.commonIndexNew >= 0;
    }

    getCommonIndexNew(): number {
        return this.commonIndexNew;
    }

    getCommonIndexPrevious(): number {
        return this.commonIndexPrevious;
    }

    getAddedActionStates(): ActionGameState[] {
        const states: ActionGameState[] = [];
        for (let index = this.commonIndexNew + 1; index < this.newStates.length; ++index) {
            const state = this.newStates[index];
            if (state instanceof ActionGameState) {
                states.push(state);
            }
        }
        return states;
    }

    private static getLastAction(
        states: GameState[],
    ): [GameState, number] | null {
        for (let index = states.length - 1; index >= 0; --index) {
            const state = states[index];
            if (state instanceof ActionGameState)
                return [state, index];
        }
        return null;
    }

    private static indexOfState(
        states: GameState[],
        state: GameState,
    ): number {
        for (let index = states.length - 1; index >= 0; --index) {
            const checkState = states[index];
            if (checkState.equals(state))
                return index;
        }
        return -1;
    }

    static create(
        newGame: Game,
        previousGame: Game,
        errorOnNoResolution?: boolean
    ): GameDiff {
        const newStates = newGame.getStates();
        const previousStates = previousGame.getStates();

        const previousAction = GameDiff.getLastAction(previousStates);
        let previousStateIndex: number = -1;
        let newStateIndex: number = -1;

        if (previousAction !== null) {
            const [previousActionState, previousActionStateIndex] = previousAction;
            previousStateIndex = previousActionStateIndex;

            newStateIndex = GameDiff.indexOfState(newStates, previousActionState);
            if (newStateIndex < 0 && errorOnNoResolution)
                throw new Error("Could not resolve common state when new game was introduced!");
        }

        if (previousAction === null || newStateIndex < 0) {
            return new GameDiff(
                newGame, previousGame,
                newStates, previousStates,
                previousAction !== null,
                -1, -1,
            );
        } else {
            return new GameDiff(
                newGame, previousGame,
                newStates, previousStates, true,
                newStateIndex, previousStateIndex,
            );
        }
    }
}
