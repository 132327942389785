import {
    ControllerProviderType,
    createControllerContext,
    useCreateController,
} from "@/app_components/game/createControllerContext";
import { PlayerStateController } from "@/ts/business/game/controller/playerstate/PlayerStateController";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";


function createPlayerStateControllerContext(): {
    PlayerStateControllerProvider: ControllerProviderType;
    usePlayerStateController: () => PlayerStateController;
    usePlayerStateDirective: () => PlayerStateDirective;
    usePlayerStateDirectiveSelector: <V>(selector: (directive: PlayerStateDirective) => V) => V;
} {
    const useCreatePlayerStateController = () => {
        return useCreateController<PlayerStateDirective, PlayerStateController>(
            () => new PlayerStateController(), [],
        );
    };
    const {
        ControllerProvider, useController, useDirective, useDirectiveSelector,
    } = createControllerContext<PlayerStateDirective, PlayerStateController>(useCreatePlayerStateController);

    return {
        PlayerStateControllerProvider: ControllerProvider,
        usePlayerStateController: useController,
        usePlayerStateDirective: useDirective,
        usePlayerStateDirectiveSelector: useDirectiveSelector,
    };
}

export const {
    PlayerStateControllerProvider,
    usePlayerStateController,
    usePlayerStateDirective,
} = createPlayerStateControllerContext();
