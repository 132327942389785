import { Game } from "@/ts/royalur/Game";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { MoveGameEvent } from "@/ts/business/game/event/MoveGameEvent";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { ListenerStore } from "@/ts/business/ListenerStore";
import { SimpleRuleSetProvider } from "@/ts/royalur/rules/simple/SimpleRuleSetProvider";
import { GameType } from "@/ts/business/game/GameType";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { Rune } from "@/ts/business/Rune";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { AnalyticsProvider } from "@/ts/business/analytics/Analytics";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { MessageOutUploadGame } from "@/ts/business/api/game/MessageOutUploadGame";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { GameAPI } from "@/ts/business/api/GameAPI";
import { UnknownLobbyPlayer } from "@/ts/business/lobby/UnknownLobbyPlayer";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { Optional } from "@/ts/util/Optional";
import { APIGameAnalysisOverview, APIGamePreferences } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { TimeControlState } from "@/ts/business/lobby/TimeControlState";
import { RuneWithEvents } from "@/ts/business/RuneWithEvents";


export type StatusListener = (status: LobbyStatus) => void;


export type GameListener = (
    game: Game,
    skipAnimation: boolean
) => void;


export type PlayerDataListener = (
    leftPlayer: PlayerType,
    yourPlayer: PlayerType | null,
    lightPlayer: GamePlayerData,
    darkPlayer: GamePlayerData
) => void;


export type AnimateDiceRollingListener = () => void;


export type ReactionListener = (
    player: PlayerType | null,
    reaction: ReactionType
) => void;


export abstract class GameSource<STATUS extends LobbyStatus> {
    private readonly analytics: AnalyticsProvider;
    private readonly gameAPI: GameAPI;
    private readonly setupSettings: LobbySettings;

    public readonly clientControls: Rune<GameClientControls>;
    public readonly preferences: Rune<APIGamePreferences>;
    public readonly lobbyStatus: Rune<STATUS>;
    public readonly players: Rune<GamePlayers>;
    public readonly game: RuneWithEvents<Game, GameUpdateEvent>;
    public readonly analysisOverview: Rune<Optional<APIGameAnalysisOverview>>;
    public readonly timeControlState: Rune<TimeControlState>;
    public readonly error: Rune<Optional<ClientError>>;

    private readonly animateDiceRollingListeners: ListenerStore<AnimateDiceRollingListener>;
    private readonly reactionListeners: ListenerStore<ReactionListener>;

    protected constructor(
        initialClientControls: GameClientControls,
        initialPreferences: APIGamePreferences,
        analytics: AnalyticsProvider,
        gameAPI: GameAPI,
        setupSettings: LobbySettings,
        initialStatus: STATUS,
        rules?: RuleSet,
        players?: GamePlayers,
        game?: Game,
    ) {
        this.analytics = analytics;
        this.gameAPI = gameAPI;
        this.setupSettings = setupSettings;

        this.clientControls = new Rune<GameClientControls>(initialClientControls);
        this.preferences = new Rune<APIGamePreferences>(initialPreferences);
        this.lobbyStatus = new Rune<STATUS>(initialStatus);

        if (!players) {
            players = new GamePlayers(
                PlayerType.LIGHT, null,
                new UnknownLobbyPlayer(new GamePlayerData(1, PlayerType.LIGHT)),
                new UnknownLobbyPlayer(new GamePlayerData(2, PlayerType.DARK)),
            );
        }
        this.players = new Rune<GamePlayers>(players);

        if (!game) {
            if (rules) {
                game = rules.generateGame();
            } else {
                // Create a temporary game.
                const ruleProvider = new SimpleRuleSetProvider();
                const rules = ruleProvider.create(GameType.FINKEL.getSettings());
                game = rules.generateGame();
            }
        }
        this.game = new RuneWithEvents<Game, GameUpdateEvent>(game);
        this.analysisOverview = new Rune<Optional<APIGameAnalysisOverview>>(Optional.empty());
        this.timeControlState = new Rune<TimeControlState>(TimeControlState.EMPTY);
        this.error = new Rune<Optional<ClientError>>(Optional.empty());

        this.animateDiceRollingListeners = new ListenerStore<AnimateDiceRollingListener>();
        this.reactionListeners = new ListenerStore<ReactionListener>();
    }

    /**
     * If more directives than this are added at once, directives
     * will start to be discarded.
     */
    abstract getControllerMaxDirectives(): number;

    getAnalytics(): AnalyticsProvider {
        return this.analytics;
    }

    getGameAPI(): GameAPI {
        return this.gameAPI;
    }

    getSetupSettings(): LobbySettings {
        return this.setupSettings;
    }

    getKnownSetupSettings(): KnownLobbySettings | null {
        return (this.setupSettings instanceof KnownLobbySettings ? this.setupSettings : null);
    }

    updatePreferences(preferences: APIGamePreferences): boolean {
        if (!this.preferences.set(preferences))
            return false;

        // Update preferences of your player as well.
        const players = this.players.get();
        const yourPlayer = players.getYourPlayer();
        if (yourPlayer) {
            const player = players.getPlayerByType(yourPlayer);
            const newPlayer = player.withPreferences(preferences);
            this.players.set(players.withPlayer(newPlayer));
        }
        return true;
    }

    recordAbortGame() {
        const game = this.game.get();
        const settings = this.getKnownSetupSettings();
        const lobbyStatus = this.lobbyStatus.get();
        if (settings && !game.isFinished() && lobbyStatus.isGameAvailable()) {
            this.analytics.recordAbortGame(settings);
        }
    }

    updateGameAndTimeControlState(
        game: Game,
        timeControlState: TimeControlState,
        gameEvent: GameUpdateEvent,
    ) {
        const emitTimeControlStateEvent = this.timeControlState.setAndDeferEvent(timeControlState);
        const emitGameEvent = this.game.setAndDeferEvent(game, gameEvent);
        emitTimeControlStateEvent();
        emitGameEvent();
    }

    updateGameAndPlayers(players: GamePlayers, game: Game, gameEvent: GameUpdateEvent) {
        const emitPlayerEvent = this.players.setAndDeferEvent(players, undefined);
        const emitGameEvent = this.game.setAndDeferEvent(game, gameEvent);
        emitPlayerEvent();
        emitGameEvent();
    }

    getRules(): RuleSet {
        return this.game.get().getRules();
    }

    addAnimateDiceRollingListener(listener: AnimateDiceRollingListener): () => void {
        return this.animateDiceRollingListeners.add(listener);
    }

    addReactionListener(listener: ReactionListener): () => void {
        return this.reactionListeners.add(listener);
    }

    /**
     * Returns whether the given player type is a local human player
     * in the current game. This means that the interactions with the
     * game for this player will be made through the user interface,
     * and not received externally.
     */
    abstract isLocalHumanPlayer(playerType: PlayerType): boolean;

    handleRematch(): void {
        const clientControls = this.clientControls.get();
        clientControls.startGame(this.getSetupSettings());
    }

    cancelRematch(): void {
        // Nothing to do by default.
    }

    abstract handleResign(): void;

    abstract setup(): () => void;

    abstract handleRoll(event: RollGameEvent): void;

    abstract handleMove(event: MoveGameEvent): void;

    protected triggerDiceRollingAnimation() {
        this.animateDiceRollingListeners.invoke();
    }

    abstract handleReaction(player: PlayerType | null, reaction: ReactionType): void;

    protected triggerReaction(player: PlayerType | null, reaction: ReactionType) {
        this.reactionListeners.invoke(player, reaction);
    }

    abstract shouldUploadGame(): boolean;

    abstract buildUploadGameMessage(): MessageOutUploadGame;
}
