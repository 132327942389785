import { GameMode } from "@/ts/business/game/GameMode";
import { PlayerNo } from "@/ts/business/game/PlayerNo";
import { BotType } from "@/ts/business/game/BotType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { RemoteLobbyStatus } from "@/ts/business/game/controller/status/RemoteLobbyStatus";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export abstract class LobbyStatus {
    abstract isRemote(): this is RemoteLobbyStatus;

    /**
     * Returns an ID that can be used to uniquely refer to this game.
     * Has different uniqueness constraints depending on the source
     * of the ID.
     */
    abstract getLobbyID(): LobbyID;

    abstract hasEverConnected(): boolean;

    abstract isConnected(): boolean;

    abstract isGameAvailable(): boolean;

    abstract isGameUnknown(): boolean;

    abstract getGameMode(): GameMode | null;

    abstract getBotType(): BotType | null;

    abstract getEndReason(): GameEndReason | null;

    abstract getEndingPlayer(): PlayerType | null;

    abstract hasPingTimes(): boolean;

    abstract getPlayer1LastPingTimeMs(): number;

    abstract getPlayer2LastPingTimeMs(): number;

    getPlayerLastPingTimeMs(playerNo: PlayerNo): number {
        if (playerNo === 1)
            return this.getPlayer1LastPingTimeMs();
        if (playerNo === 2)
            return this.getPlayer2LastPingTimeMs();

        throw new Error("Unexpected playerNo: " + playerNo);
    }

    abstract getCompleteGameID(): string | null;

    abstract withCompleteGameID(completeGameID: string | null): LobbyStatus;

    abstract withEnd(endReason: GameEndReason, endingPlayer: PlayerType): LobbyStatus;

    abstract getRematchRequestedBy(): PlayerNo | null;
}
