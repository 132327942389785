import { WaitingForRollGameState } from "@/ts/royalur/rules/state/WaitingForRollGameState";
import { RolledGameState } from "@/ts/royalur/rules/state/RolledGameState";
import { WaitingForMoveGameState } from "@/ts/royalur/rules/state/WaitingForMoveGameState";
import { MovedGameState } from "@/ts/royalur/rules/state/MovedGameState";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { Game } from "@/ts/royalur/Game";


export class MoveInfo {
    waitingForRollState: WaitingForRollGameState | null;
    rolledState: RolledGameState | null;
    waitingForMoveState: WaitingForMoveGameState | null;
    moveState: MovedGameState | null;
    afterState: GameState;
    previousMove: MoveInfo | null;

    constructor(
        waitingForRollState: WaitingForRollGameState | null,
        rolledState: RolledGameState | null,
        waitingForMoveState: WaitingForMoveGameState | null,
        moveState: MovedGameState | null,
        afterState: GameState,
        previousMove: MoveInfo | null,
    ) {
        this.waitingForRollState = waitingForRollState;
        this.rolledState = rolledState;
        this.waitingForMoveState = waitingForMoveState;
        this.moveState = moveState;
        this.afterState = afterState;
        this.previousMove = previousMove;
    }

    static listMoves(game: Game): MoveInfo[] {
        const states = game.getStates();
        const moves: MoveInfo[] = [];

        for (let index = 3; index < states.length - 1; ++index) {
            const state = states[index];
            if (state instanceof MovedGameState) {
                const waitingForRollState = states[index - 3];
                const rolledState = states[index - 2];
                const waitingForMoveState = states[index - 1];

                if (!(waitingForMoveState instanceof WaitingForMoveGameState))
                    throw new Error("Missing waiting for move state");
                if (!(rolledState instanceof RolledGameState))
                    throw new Error("Missing rolled state");
                if (!(waitingForRollState instanceof WaitingForRollGameState))
                    throw new Error("Missing waiting for roll state");

                const previousMove = moves[moves.length - 1] ?? null;
                moves.push({
                    waitingForRollState, rolledState, waitingForMoveState,
                    moveState: state,
                    afterState: states[index + 1],
                    previousMove,
                });
                if (index + 1 >= states.length - 1)
                    return moves;

            } else if (state instanceof RolledGameState) {
                const waitingForRollState = states[index - 1];
                const wfmState = states[index + 1];
                if (wfmState instanceof WaitingForMoveGameState)
                    continue;
                if (!(waitingForRollState instanceof WaitingForRollGameState))
                    throw new Error("Missing waiting for roll state");

                const previousMove = moves[moves.length - 1] ?? null;
                moves.push({
                    waitingForRollState,
                    rolledState: state,
                    waitingForMoveState: null,
                    moveState: null,
                    afterState: states[index + 1],
                    previousMove,
                });
                if (index + 1 >= states.length - 1)
                    return moves;
            }
        }

        const previousMove = moves[moves.length - 1] ?? null;
        moves.push({
            waitingForRollState: null,
            rolledState: null,
            waitingForMoveState: null,
            moveState: null,
            afterState: states[states.length - 1],
            previousMove,
        });
        return moves;
    }
}
