import { GameEvent } from "@/ts/business/game/event/GameEvent";


export class NavigationType {
    public static readonly NEXT = new NavigationType("next", true, false);
    public static readonly PREV = new NavigationType("prev", false, true);
    public static readonly NEXT_KEY = new NavigationType("next_key", true, false);
    public static readonly PAUSE = new NavigationType("pause", false, false);
    public static readonly PLAY = new NavigationType("play", false, false);

    static values(): NavigationType[] {
        return [
            NavigationType.NEXT,
            NavigationType.PREV,
            NavigationType.NEXT_KEY,
            NavigationType.PAUSE,
            NavigationType.PLAY,
        ];
    }

    private readonly id: string;
    private readonly forwards: boolean;
    private readonly backwards: boolean;

    constructor(id: string, forwards: boolean, backwards: boolean) {
        this.id = id;
        this.forwards = forwards;
        this.backwards = backwards;
    }

    getID(): string {
        return this.id;
    }

    isForwards(): boolean {
        return this.forwards;
    }

    isBackwards(): boolean {
        return this.backwards;
    }
}


export class NavigationGameEvent extends GameEvent {
    private readonly type: NavigationType;
    private readonly moveIndex: number | null;

    constructor(type: NavigationType, moveIndex?: number | null) {
        super("navigation");
        this.type = type;
        this.moveIndex = (moveIndex !== undefined ? moveIndex : null);
    }

    getType(): NavigationType {
        return this.type;
    }

    getMoveIndexOrNull(): number | null {
        return this.moveIndex;
    }
}
