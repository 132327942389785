import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";


/**
 * A directive for rendering the state of the players while moves are animating.
 */
export class ReplayPlayerStatesDirective extends PlayerStateDirective {
    readonly states: PlayerStateDirective[];

    constructor(
        directiveID: number,
        states: PlayerStateDirective[],
    ) {
        super(directiveID, states[0].moveIndex);
        this.states = states;
    }

    getState(moveIndex: number): PlayerStateDirective {
        for (let index = 0; index < this.states.length; ++index) {
            const state = this.states[index];
            if (state.moveIndex === moveIndex)
                return state;
        }
        return (moveIndex <= this.moveIndex ? this.states[0] : this.states[this.states.length - 1]);
    }

    override getActivePlayer(moveIndex: number): PlayerType {
        return this.getState(moveIndex).getActivePlayer(moveIndex);
    }

    override getLightPlayerState(moveIndex: number): PlayerState {
        return this.getState(moveIndex).getLightPlayerState(moveIndex);
    }

    override getDarkPlayerState(moveIndex: number): PlayerState {
        return this.getState(moveIndex).getDarkPlayerState(moveIndex);
    }
}
