import { GameMode } from "@/ts/business/game/GameMode";
import { BotType } from "@/ts/business/game/BotType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerNo } from "@/ts/business/game/PlayerNo";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class RemoteLobbyStatus extends LobbyStatus {
    private readonly lobbyID: LobbyID;
    private readonly everConnected: boolean;
    private readonly connected: boolean;
    private readonly gameAvailable: boolean;
    private readonly gameUnknown: boolean;
    private readonly gameMode: GameMode | null;
    private readonly botType: BotType | null;
    private readonly endReason: GameEndReason | null;
    private readonly endingPlayer: PlayerType | null;
    private readonly player1LastPingTimeMs: number;
    private readonly player2LastPingTimeMs: number;
    private readonly completeGameID: string | null;
    private readonly rematchRequestedBy: PlayerNo | null;

    constructor(
        lobbyID: LobbyID,
        everConnected: boolean,
        connected: boolean,
        gameAvailable: boolean,
        gameUnknown: boolean,
        gameMode: GameMode | null,
        botType: BotType | null,
        endReason: GameEndReason | null,
        endingPlayer: PlayerType | null,
        player1LastPingTimeMs: number,
        player2LastPingTimeMs: number,
        completeGameID: string | null,
        rematchRequestedBy: PlayerNo | null,
    ) {
        super();
        if (!lobbyID.getSource().isLiveLobbyID())
            throw new Error("Remote lobby status expects a live lobby ID");

        this.lobbyID = lobbyID;
        this.everConnected = everConnected;
        this.connected = connected;
        this.gameAvailable = gameAvailable;
        this.gameUnknown = gameUnknown;
        this.gameMode = gameMode;
        this.botType = botType;
        this.endReason = endReason;
        this.endingPlayer = endingPlayer;
        this.player1LastPingTimeMs = player1LastPingTimeMs;
        this.player2LastPingTimeMs = player2LastPingTimeMs;
        this.completeGameID = completeGameID;
        this.rematchRequestedBy = rematchRequestedBy;
    }

    static createDefault(lobbyID: LobbyID): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            lobbyID,
            false, // everConnected
            false, // connected
            false, // gameAvailable
            false, // gameUnknown
            null, // gameMode
            null, // botType
            null, // endReason
            null, // endingPlayer
            0, // player1LastPingTimeMs
            0, // player2LastPingTimeMs
            null, // completeGameID
            null, // rematchRequestedBy
        );
    }

    override isRemote(): this is RemoteLobbyStatus {
        return true;
    }

    override getLobbyID(): LobbyID {
        return this.lobbyID;
    }

    override hasEverConnected(): boolean {
        return this.everConnected;
    }

    override isConnected(): boolean {
        return this.connected;
    }

    override isGameAvailable(): boolean {
        return this.gameAvailable;
    }

    override isGameUnknown(): boolean {
        return this.gameUnknown;
    }

    override getGameMode(): GameMode | null {
        return this.gameMode;
    }

    override getBotType(): BotType | null {
        return this.botType;
    }

    override getEndReason(): GameEndReason | null {
        return this.endReason;
    }

    override getEndingPlayer(): PlayerType | null {
        return this.endingPlayer;
    }

    override hasPingTimes(): boolean {
        return true;
    }

    override getPlayer1LastPingTimeMs(): number {
        return this.player1LastPingTimeMs;
    }

    override getPlayer2LastPingTimeMs(): number {
        return this.player2LastPingTimeMs;
    }

    override getCompleteGameID(): string | null {
        return this.completeGameID;
    }

    override getRematchRequestedBy(): PlayerNo | null {
        return this.rematchRequestedBy;
    }

    withConnected(connected: boolean): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected || connected,
            connected,
            this.gameAvailable,
            this.gameUnknown,
            this.gameMode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            this.player1LastPingTimeMs,
            this.player2LastPingTimeMs,
            this.completeGameID,
            this.rematchRequestedBy,
        );
    }

    withGameAvailable(gameAvailable: boolean): RemoteLobbyStatus {
        if (gameAvailable === this.gameAvailable)
            return this;

        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            gameAvailable,
            this.gameUnknown,
            this.gameMode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            this.player1LastPingTimeMs,
            this.player2LastPingTimeMs,
            this.completeGameID,
            this.rematchRequestedBy,
        );
    }

    withGameUnknown(gameUnknown: boolean): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            this.gameAvailable,
            gameUnknown,
            this.gameMode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            this.player1LastPingTimeMs,
            this.player2LastPingTimeMs,
            this.completeGameID,
            this.rematchRequestedBy,
        );
    }

    withGameInfo(
        gameMode: GameMode | null,
        botType: BotType | null,
        endReason: GameEndReason | null,
        endingPlayer: PlayerType | null,
        player1LastPingTimeMs: number,
        player2LastPingTimeMs: number,
        completeGameID: string | null,
        rematchRequestedBy: PlayerNo | null,
    ): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            this.gameAvailable,
            this.gameUnknown,
            gameMode,
            botType,
            endReason,
            endingPlayer,
            player1LastPingTimeMs,
            player2LastPingTimeMs,
            completeGameID,
            rematchRequestedBy,
        );
    }

    withPlayerConnected(playerNo: number, connected: boolean): RemoteLobbyStatus {
        let player1LastPingTimeMs = this.player1LastPingTimeMs;
        let player2LastPingTimeMs = this.player2LastPingTimeMs;
        if (playerNo === 1) {
            player1LastPingTimeMs = (connected ? Date.now() : 0);
        } else if (playerNo === 2) {
            player2LastPingTimeMs = (connected ? Date.now() : 0);
        } else {
            throw new Error(`Unknown player no ${playerNo}`);
        }
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            this.gameAvailable,
            this.gameUnknown,
            this.gameMode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            player1LastPingTimeMs,
            player2LastPingTimeMs,
            this.completeGameID,
            this.rematchRequestedBy,
        );
    }

    withCompleteGameID(completeGameID: string | null): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            this.gameAvailable,
            this.gameUnknown,
            this.gameMode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            this.player1LastPingTimeMs,
            this.player2LastPingTimeMs,
            completeGameID,
            this.rematchRequestedBy,
        );
    }

    override withEnd(
        endReason: GameEndReason,
        endingPlayer: PlayerType,
    ): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            this.gameAvailable,
            this.gameUnknown,
            this.gameMode,
            this.botType,
            endReason,
            endingPlayer,
            this.player1LastPingTimeMs,
            this.player2LastPingTimeMs,
            this.completeGameID,
            this.rematchRequestedBy,
        );
    }

    withRematchRequestedBy(rematchRequestedBy: PlayerNo | null): RemoteLobbyStatus {
        return new RemoteLobbyStatus(
            this.lobbyID,
            this.everConnected,
            this.connected,
            this.gameAvailable,
            this.gameUnknown,
            this.gameMode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            this.player1LastPingTimeMs,
            this.player2LastPingTimeMs,
            this.completeGameID,
            rematchRequestedBy,
        );
    }
}
