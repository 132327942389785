import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { Tile } from "@/ts/royalur/model/Tile";


export class PacketOutMove extends PacketOut {
    public static readonly SOURCE_TILE_KEY = "source_tile";

    private readonly sourceTile: Tile | null;

    constructor(sourceTile: Tile | null) {
        super(OutboundPacketType.MOVE);
        this.sourceTile = sourceTile;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            [PacketOutMove.SOURCE_TILE_KEY]: (this.sourceTile?.toString() ?? null),
        };
    }
}
