import { MessageOut } from "@/ts/business/api/MessageOut";
import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { GameMode } from "@/ts/business/game/GameMode";
import { Game } from "@/ts/royalur/Game";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class MessageOutUploadGame extends MessageOut {
    public readonly userLobbyID: string;
    public readonly gameMode: GameMode;
    public readonly player1: LobbyPlayer;
    public readonly player2: LobbyPlayer;
    public readonly game: Game;

    constructor(
        userLobbyID: string,
        gameMode: GameMode,
        player1: LobbyPlayer,
        player2: LobbyPlayer,
        game: Game,
    ) {
        super();
        this.userLobbyID = userLobbyID;
        this.gameMode = gameMode;
        this.player1 = player1;
        this.player2 = player2;
        this.game = game;
    }

    write(context: MessageIOContext): Record<string, any> {
        return {
            user_lobby_id: this.userLobbyID,
            game_mode: this.gameMode.getID(),
            player1: context.getLobbyNotation().writeLobbyPlayer(this.player1),
            player2: context.getLobbyNotation().writeLobbyPlayer(this.player2),
            game: context.getGameNotation().writeGame(this.game),
        };
    }
}
