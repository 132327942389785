import {
    ControllerProviderType,
    createControllerContext,
    useCreateController,
} from "@/app_components/game/createControllerContext";
import { DiceController } from "@/ts/business/game/controller/dice/DiceController";
import { DiceDirective } from "@/ts/business/game/controller/dice/DiceDirective";


function createDiceControllerContext(): {
    DiceControllerProvider: ControllerProviderType;
    useDiceController: () => DiceController;
    useDiceDirective: () => DiceDirective;
} {
    const useCreateDiceController = () => {
        return useCreateController<DiceDirective, DiceController>(
            () => new DiceController(), [],
        );
    };
    const {
        ControllerProvider, useController, useDirective,
    } = createControllerContext<DiceDirective, DiceController>(useCreateDiceController);

    return {
        DiceControllerProvider: ControllerProvider,
        useDiceController: useController,
        useDiceDirective: useDirective,
    };
}

export const {
    DiceControllerProvider, useDiceController, useDiceDirective,
} = createDiceControllerContext();
