import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { StaticImageData } from "next/image";
import { MusicTrack } from "@/app_components/assets/MusicTrack";
import woodBlueBg from "@/res/backgrounds/wood_blue.png";
import lightPieceSelected from "@/res/game/default/light_piece_selected.png";
import lightPieceUnselected from "@/res/game/default/light_piece_unselected.png";
import darkPieceSelected from "@/res/game/default/dark_piece_selected.png";
import darkPieceUnselected from "@/res/game/default/dark_piece_unselected.png";
import board from "@/res/game/default/board.png";
import boardJSON from "@/res/game/default/board.json";
import diceUp1 from "@/res/game/default/dice/up1.png";
import diceUp2 from "@/res/game/default/dice/up2.png";
import diceUp3 from "@/res/game/default/dice/up3.png";
import diceDown1 from "@/res/game/default/dice/down1.png";
import diceDown2 from "@/res/game/default/dice/down2.png";
import diceDown3 from "@/res/game/default/dice/down3.png";
import selectedShadow from "@/res/game/default/dice/selected_shadow.png";
import unselectedShadow from "@/res/game/default/dice/unselected_shadow.png";
import pickupSoundWebm from "@/res/audio/pickup.webm";
import pickupSoundMp3 from "@/res/audio/pickup.mp3";
import placeSoundWebm from "@/res/audio/place.webm";
import placeSoundMp3 from "@/res/audio/place.mp3";
import rosetteSoundWebm from "@/res/audio/rosette.webm";
import rosetteSoundMp3 from "@/res/audio/rosette.mp3";
import captureSoundWebm from "@/res/audio/capture.webm";
import captureSoundMp3 from "@/res/audio/capture.mp3";
import scoreSoundWebm from "@/res/audio/score.webm";
import scoreSoundMp3 from "@/res/audio/score.mp3";
import victorySoundWebm from "@/res/audio/victory.webm";
import victorySoundMp3 from "@/res/audio/victory.mp3";
import errorSoundWebm from "@/res/audio/error.webm";
import errorSoundMp3 from "@/res/audio/error.mp3";
import diceSelectSound1Webm from "@/res/audio/select1.webm";
import diceSelectSound1Mp3 from "@/res/audio/select1.mp3";
import diceSelectSound2Webm from "@/res/audio/select2.webm";
import diceSelectSound2Mp3 from "@/res/audio/select2.mp3";
import diceSelectSound3Webm from "@/res/audio/select3.webm";
import diceSelectSound3Mp3 from "@/res/audio/select3.mp3";
import diceRoll1Webm from "@/res/audio/dice_roll1.webm";
import diceRoll1Mp3 from "@/res/audio/dice_roll1.mp3";
import diceRoll2Webm from "@/res/audio/dice_roll2.webm";
import diceRoll2Mp3 from "@/res/audio/dice_roll2.mp3";
import diceRoll3Webm from "@/res/audio/dice_roll3.webm";
import diceRoll3Mp3 from "@/res/audio/dice_roll3.mp3";
import diceRoll4Webm from "@/res/audio/dice_roll4.webm";
import diceRoll4Mp3 from "@/res/audio/dice_roll4.mp3";
import questForTheRoyalGameWebm from "@/res/audio/track_quest_for_the_royal_game.webm";
import questForTheRoyalGameMp3 from "@/res/audio/track_quest_for_the_royal_game.mp3";


export type BoardSpecification = [number, number, number, number][];

export class GameThemeSettings {
    themeType: GameThemeType = GameThemeType.DEFAULT;
    backgroundTile: StaticImageData = woodBlueBg;
    lightPieceSelected: StaticImageData = lightPieceSelected;
    lightPieceUnselected: StaticImageData = lightPieceUnselected;
    darkPieceSelected: StaticImageData = darkPieceSelected;
    darkPieceUnselected: StaticImageData = darkPieceUnselected;
    board: StaticImageData = board;
    boardJSON: BoardSpecification = boardJSON as BoardSpecification;
    behindBoardBgData: StaticImageData | null = null;
    diceUpImages: StaticImageData[] = [diceUp1, diceUp2, diceUp3];
    diceDownImages: StaticImageData[] = [diceDown1, diceDown2, diceDown3];
    diceSelectedShadow: StaticImageData = selectedShadow;
    diceUnselectedShadow: StaticImageData = unselectedShadow;
    pickupSound: string[] = [pickupSoundWebm, pickupSoundMp3];
    placeSound: string[] = [placeSoundWebm, placeSoundMp3];
    rosetteSound: string[] = [rosetteSoundWebm, rosetteSoundMp3];
    captureSound: string[] = [captureSoundWebm, captureSoundMp3];
    scoreSound: string[] = [scoreSoundWebm, scoreSoundMp3];
    victorySound: string[] = [victorySoundWebm, victorySoundMp3];
    errorSound: string[] = [errorSoundWebm, errorSoundMp3];
    tracks: MusicTrack[] = [
        new MusicTrack(
            "Quest for the Royal Game", "Olli Oja",
            [{
                url: "https://youtu.be/7O5t14j-WJg",
                linkText: "Listen on YouTube",
            }],
            [questForTheRoyalGameWebm, questForTheRoyalGameMp3],
        ),
    ];

    diceSelectSounds: string[][] = [
        [diceSelectSound1Webm, diceSelectSound1Mp3],
        [diceSelectSound2Webm, diceSelectSound2Mp3],
        [diceSelectSound3Webm, diceSelectSound3Mp3],
        [diceSelectSound3Webm, diceSelectSound3Mp3],
    ];

    diceRollSounds: string[][] = [
        [diceRoll1Webm, diceRoll1Mp3],
        [diceRoll2Webm, diceRoll2Mp3],
        [diceRoll3Webm, diceRoll3Mp3],
        [diceRoll4Webm, diceRoll4Mp3],
    ];

    getFireworkColour(): { r: number; g: number; b: number } {
        return Math.random() > 0.5 ? {
            r: 115, g: 182, b: 255,
        } : {
            r: 255, g: 205, b: 69,
        };
    }
}
