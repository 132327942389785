import {
    createContext, DependencyList, ReactNode,
    useContext, useEffect, useRef,
    useSyncExternalStore,
} from "react";
import { Controller } from "@/ts/business/game/controller/Controller";
import { Directive } from "@/ts/business/game/controller/Directive";
import { areDependenciesEqual } from "@/app_util/areDependenciesEqual";

interface ProviderProps {
    children: ReactNode;
}

export type ControllerProviderType = (props: ProviderProps) => ReactNode;

interface ControllerCache<D extends Directive, C extends Controller<D>> {
    controller: Exclude<C, void>;
    dependencies: React.DependencyList;
}


/**
 * Creates a controller and uses useEffect to make its subscriptions
 * to child controllers and clean them up when the component unmounts.
 */
export function useCreateController<
    D extends Directive, C extends Controller<D>,
>(
    newControllerFn: () => Exclude<C, void>,
    dependencies: DependencyList,
): C {
    const cacheRef = useRef<ControllerCache<D, C> | null>(null);

    let controller: Exclude<C, void>;
    const current = cacheRef.current;
    if (current === null || !areDependenciesEqual(dependencies, current.dependencies)) {
        controller = newControllerFn();
        cacheRef.current = {
            controller: controller,
            dependencies: dependencies,
        };
    } else {
        controller = current.controller;
    }

    useEffect(() => controller.setup(), [controller]);
    return controller;
}


interface ControllerContextResult<D extends Directive, C extends Controller<D>> {
    ControllerProvider: (props: ProviderProps) => ReactNode;
    useController: () => C;
    useOptionalController: () => C | null;
    useDirective: () => D;
    useDirectiveSelector: <V>(selector: (directive: D) => V) => V;
}


/**
 * Create context for a controller.
 */
export function createControllerContext<D extends Directive, C extends Controller<D>>(
    useCreateController: () => C,
): ControllerContextResult<D, C> {
    // The context stores the controller.
    const ControllerContext = createContext<C | null>(null);

    // Creates and updates the controller.
    function ControllerProvider({ children }: ProviderProps) {
        const controller = useCreateController();
        return (
            <ControllerContext.Provider value={controller}>
                {children}
            </ControllerContext.Provider>
        );
    }

    // Uses the controller from context.
    function useOptionalController(): C | null {
        return useContext(ControllerContext);
    }

    // Uses the controller from context.
    function useController(): C {
        const controller = useOptionalController();
        if (!controller)
            throw new Error("Controller not found");
        return controller;
    }

    /**
     * Uses data from the currently active directive.
     */
    function useDirectiveSelector<V>(selector: (directive: D) => V): V {
        const controller = useController();
        return useSyncExternalStore(
            callback => controller.subscribeToActiveDirective(callback),
            () => selector(controller.getActiveDirective()),
            () => selector(controller.getActiveDirective()),
        );
    }

    /**
     * Uses the currently active directive.
     */
    function useDirective(): D {
        return useDirectiveSelector(d => d);
    }

    return {
        ControllerProvider,
        useController,
        useOptionalController,
        useDirective,
        useDirectiveSelector,
    };
}
