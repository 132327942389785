

export abstract class GameEvent {
    private readonly name: string;

    protected constructor(name: string) {
        this.name = name;
    }

    getName(): string {
        return this.name;
    }
}
