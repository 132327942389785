import { ListenerStore } from "@/ts/business/ListenerStore";


export type RuneWithEventsListener<V, E> = ((value: V, event: E, previousValue: V | null) => void);


/**
 * Supports sharing an up-to-date, non-animation, value with
 * the user interface. This is an alternative to using directives
 * for data that should not be animated.
 */
export class RuneWithEvents<
    V extends Exclude<any, null | undefined>,
    E,
> {
    private readonly listeners: ListenerStore<RuneWithEventsListener<V, E>>;
    private value: V | null;

    constructor(initialValue?: V | null) {
        this.listeners = new ListenerStore<RuneWithEventsListener<V, E>>();
        this.value = initialValue ?? null;
    }

    subscribe(listener: RuneWithEventsListener<V, E>): () => void {
        return this.listeners.add(listener);
    }

    get(): V {
        if (this.value === null)
            throw new Error("This rune is empty!");

        return this.value;
    }

    set(value: V, event: E, requiredLastValue?: V): boolean {
        const [updated, previousValue] = this.setWithoutEvent(value, requiredLastValue);
        this.emitUpdateEvent(value, event, previousValue);
        return updated;
    }

    setAndDeferEvent(value: V, event: E, requiredLastValue?: V): () => void {
        const [_, previousValue] = this.setWithoutEvent(value, requiredLastValue);
        return () => this.emitUpdateEvent(value, event, previousValue);
    }

    private setWithoutEvent(value: V, requiredLastValue?: V): [boolean, V | null] {
        const previousValue = this.value;
        if (previousValue === value)
            return [true, previousValue];
        if (requiredLastValue !== undefined && previousValue !== requiredLastValue)
            return [false, previousValue];

        this.value = value;
        return [true, previousValue];
    }

    private emitUpdateEvent(value: V, event: E, previousValue: V | null) {
        this.listeners.invoke(value, event, previousValue);
    }
}
