import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { Board } from "@/ts/royalur/model/Board";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Move } from "@/ts/royalur/model/Move";
import { MoveCategory } from "@/ts/business/analysis/MoveCategory";


export interface MoveHighlight {
    move: Move;
    category: MoveCategory;
}


/**
 * A directive for rendering the dice.
 */
export class AnalysisBoardDirective extends BoardDirective {
    readonly activePlayer: PlayerType;
    readonly board: Board;
    readonly previewMove: Move;
    readonly moveHighlights: MoveHighlight[];

    constructor(
        directiveID: number,
        rules: RuleSet,
        moveIndex: number,
        activePlayer: PlayerType,
        board: Board,
        previewMove: Move,
        moveHighlights: MoveHighlight[],
    ) {
        super(directiveID, rules, moveIndex);
        this.activePlayer = activePlayer;
        this.board = board;
        this.previewMove = previewMove;
        this.moveHighlights = moveHighlights;
    }

    isLimboDirective(): boolean {
        return true;
    }

    override getBoard(): Board {
        return this.board;
    }

    override getActivePlayer(): PlayerType {
        return this.activePlayer;
    }
}
