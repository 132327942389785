import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { MessageOut } from "@/ts/business/api/MessageOut";

export abstract class PacketOut extends MessageOut {
    public static readonly PACKET_TYPE_KEY = "packet_type";

    public readonly type: OutboundPacketType;

    protected constructor(type: OutboundPacketType) {
        super();
        this.type = type;
    }

    override write(context: MessageIOContext): Record<string, any> {
        return {
            [PacketOut.PACKET_TYPE_KEY]: this.type.getName(),
            ...this.writeContents(context),
        };
    }

    abstract writeContents(context: MessageIOContext): Record<string, any>;
}
