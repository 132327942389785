import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutReOpen extends PacketOut {
    public static readonly PROTOCOL_VERSION_KEY = "protocol_version";
    public static readonly PREVIOUS_ID_KEY = "previous_id";
    public static readonly NAME_KEY = "name";

    private readonly protocolVersion: number;
    private readonly previousID: string;
    private readonly name: string;

    constructor(protocolVersion: number, previousID: string, name: string) {
        super(OutboundPacketType.REOPEN);
        this.protocolVersion = protocolVersion;
        this.previousID = previousID;
        this.name = name;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            [PacketOutReOpen.PROTOCOL_VERSION_KEY]: this.protocolVersion,
            [PacketOutReOpen.PREVIOUS_ID_KEY]: this.previousID,
            [PacketOutReOpen.NAME_KEY]: this.name,
        };
    }
}
