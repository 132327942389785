import { GameEvent } from "@/ts/business/game/event/GameEvent";
import { Move } from "@/ts/royalur/model/Move";


export class MoveGameEvent extends GameEvent {
    private readonly move: Move;
    private readonly skipAnimation: boolean;

    constructor(move: Move, skipAnimation: boolean) {
        super("move");
        this.move = move;
        this.skipAnimation = skipAnimation;
    }

    getMove(): Move {
        return this.move;
    }

    getSkipAnimation(): boolean {
        return this.skipAnimation;
    }
}
