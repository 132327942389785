import { min } from "@/ts/util/numbers";

/**
* A vector with two elements.
*/
export class Vec2 {
    readonly x: number;
    readonly y: number;

    constructor(x: number, y: number) {
        if (isNaN(x) || isNaN(y))
            throw new Error("x and y cannot be NaN: " + x + ", " + y);

        this.x = x;
        this.y = y;
    }

    isInt(): boolean {
        return Number.isInteger(this.x) && Number.isInteger(this.y);
    }

    toString(): string {
        return "Vec2(" + this.x + ", " + this.y + ")";
    }

    /**
     * Creates a new vector with the same direction as this vector,
     * but with a unit length.
     */
    toUnitLength(): Vec2 {
        return this.toLength(1);
    }

    /**
     * Creates a new vector with the same direction as this vector,
     * but with the given length.
     */
    toLength(length: number): Vec2 {
        const currentLength = this.length();
        const factor = length / currentLength;
        return new Vec2(
            this.x * factor,
            this.y * factor,
        );
    }

    /**
     * Creates a new vector with all app_components rounded to integers.
     */
    round(): Vec2 {
        return new Vec2(
            Math.round(this.x),
            Math.round(this.y),
        );
    }

    /**
     * Gets the angle that this vector points, clockwise from
     * the positive x-axis, in Radians.
     */
    getAngle(): number {
        return Math.atan2(this.y, this.x);
    }

    /**
     * Rotates this vector clockwise by the given angle in Radians.
     */
    rotate(angleRadians: number): Vec2 {
        const length = this.length();
        const newAngle = this.getAngle() + angleRadians;
        return new Vec2(
            length * Math.cos(newAngle),
            length * Math.sin(newAngle),
        );
    }

    add(other: Vec2): Vec2 {
        return new Vec2(this.x + other.x, this.y + other.y);
    }

    sub(other: Vec2): Vec2 {
        return new Vec2(this.x - other.x, this.y - other.y);
    }

    mul(scale: number): Vec2 {
        if (scale === 1)
            return this;
        return new Vec2(this.x * scale, this.y * scale);
    }

    lengthSq(): number {
        return this.x * this.x + this.y * this.y;
    }

    length(): number {
        return Math.sqrt(this.lengthSq());
    }

    distSquared(other: Vec2): number {
        return this.sub(other).lengthSq();
    }

    dist(other: Vec2): number {
        return this.sub(other).length();
    }

    /**
     * Calculates the distance of this point from a line
     * given by two points on the line.
     */
    perpendicularDist(lineStart: Vec2, lineEnd: Vec2): number {
        const dx = lineEnd.x - lineStart.x;
        const dy = lineEnd.y - lineStart.y;
        const lengthSquared = dy * dy + dx * dx;
        if (lengthSquared <= 0.01)
            return this.dist(lineStart);

        const num = Math.abs(
            dy * this.x - dx * this.y + lineEnd.x * lineStart.y - lineEnd.y * lineStart.x,
        );
        const length = Math.sqrt(lengthSquared);
        return num / length;
    }

    equals(other: Vec2): boolean {
        return this.x === other.x && this.y === other.y;
    }

    static areEqual(vec1: Vec2 | null | undefined, vec2: Vec2 | null | undefined): boolean {
        if (vec1 === vec2)
            return true;
        if (!vec1 || !vec2)
            return false;
        return vec1.equals(vec2);
    }

    /**
     * Takes a unit length step towards the other vector.
     */
    stepTowards(other: Vec2): Vec2 {
        if (!this.isInt() || !other.isInt())
            throw new Error("stepTowards only works on integer vectors");

        const dx = other.x - this.x;
        const dy = other.y - this.y;

        if (Math.abs(dx) + Math.abs(dy) <= 1)
            return other;

        if (Math.abs(dx) < Math.abs(dy)) {
            return new Vec2(this.x, this.y + Math.sign(dy));
        } else {
            return new Vec2(this.x + Math.sign(dx), this.y);
        }
    }

    floor(): Vec2 {
        return new Vec2(Math.floor(this.x), Math.floor(this.y));
    }

    static dotProduct(v1: Vec2, v2: Vec2): number {
        return v1.x * v2.x + v1.y * v2.y;
    }

    static project(v1: Vec2, v2: Vec2): number {
        return Vec2.dotProduct(v1, v2) / v2.length();
    }

    static distanceSq(v1: Vec2, v2: Vec2): number {
        const dx = v1.x - v2.x;
        const dy = v1.y - v2.y;
        return dx * dx + dy * dy;
    }

    static manhattanDistance(v1: Vec2, v2: Vec2): number {
        const dx = v2.x - v1.x;
        const dy = v2.y - v1.y;
        return Math.abs(dx) + Math.abs(dy);
    }

    static distance(v1: Vec2, v2: Vec2): number {
        return Math.sqrt(Vec2.distanceSq(v1, v2));
    }

    static midpoint(v1: Vec2, v2: Vec2): Vec2 {
        return new Vec2(
            (v1.x + v2.x) / 2,
            (v1.y + v2.y) / 2,
        );
    }

    /**
     * Linearly interpolate between {@param v1} and {@param v2} with {@param t}
     * where t=0 gives v1, and t=1 gives v2.
     */
    static linearlyInterpolate(v1: Vec2, v2: Vec2, t: number): Vec2 {
        return new Vec2(
            v1.x * (1 - t) + v2.x * t,
            v1.y * (1 - t) + v2.y * t,
        );
    }

    static quadratic(a: Vec2, b: Vec2, c: Vec2, t: number): Vec2 {
        return new Vec2(
            0.5 * a.x * t * t + b.x * t + c.x,
            0.5 * a.y * t * t + b.y * t + c.y,
        );
    }

    static polar(length: number, angle: number): Vec2 {
        return new Vec2(
            Math.cos(angle) * length,
            Math.sin(angle) * length,
        );
    }

    /**
     * Appends a line connecting v1 and v2 into dest.
     */
    static appendLineNoStartEnd(dest: Vec2[], v1: Vec2, v2: Vec2, resolution: number) {
        const steps = Math.ceil(Vec2.distance(v1, v2) / resolution);
        for (let step = 1; step < steps; ++step) {
            dest.push(Vec2.linearlyInterpolate(v1, v2, step / steps));
        }
    }

    /**
     * Creates a line connecting v1 and v2.
     */
    static createLine(v1: Vec2, v2: Vec2, resolution?: number): Vec2[] {
        resolution = (resolution === undefined ? 5 : resolution);
        const line: Vec2[] = [v1];
        Vec2.appendLineNoStartEnd(line, v1, v2, resolution);
        line.push(v2);
        return line;
    }

    static findQuadraticBezierPoint(prev: Vec2, curr: Vec2, next: Vec2, t: number): Vec2 {
        const a = (1 - t) * (1 - t);
        const b = 2 * (1 - t) * t;
        const c = t * t;
        return new Vec2(
            a * prev.x + b * curr.x + c * next.x,
            a * prev.y + b * curr.y + c * next.y,
        );
    }

    /**
     * Given the list of waypoints, constructs a quadratic Bézier curve.
     */
    static createBezierCurve(waypoints: Vec2[], resolution?: number): Vec2[] {
        resolution = (resolution === undefined ? 5 : resolution);
        if (resolution <= 0)
            throw new Error("Impossible resolution! " + resolution);

        if (waypoints.length <= 1)
            return waypoints;

        const curve: Vec2[] = [];

        // Add a straight line from the start to the first midpoint
        curve.push(waypoints[0]);
        const beginFrom = waypoints[0];
        const beginTo = waypoints[1];
        Vec2.appendLineNoStartEnd(
            curve, beginFrom, Vec2.midpoint(beginFrom, beginTo), resolution,
        );

        for (let index = 1; index < waypoints.length - 1; ++index) {
            const curr = waypoints[index];
            const prev = Vec2.midpoint(curr, waypoints[index - 1]);
            const next = Vec2.midpoint(curr, waypoints[index + 1]);

            const curveIndex = curve.length;
            curve.push(prev);
            curve.push(next);

            let dist = Vec2.manhattanDistance(next, prev);
            while (dist > resolution) {
                const oldCount = curve.length - curveIndex,
                    newCount = 2 * oldCount - 1,
                    splits = newCount - oldCount;

                for (let splitIndex = 0; splitIndex < splits; ++splitIndex) {
                    const belowIndex = curveIndex + splitIndex * 2;
                    const below = curve[belowIndex];
                    const above = curve[belowIndex + 1];
                    const t = (splitIndex * 2 + 1) / (newCount - 1);
                    const point = Vec2.findQuadraticBezierPoint(prev, curr, next, t);

                    curve.splice(belowIndex + 1, 0, point);
                    const belowDist = Vec2.manhattanDistance(point, below);
                    const aboveDist = Vec2.manhattanDistance(point, above);
                    dist = min(dist, min(belowDist, aboveDist));
                }
            }
        }

        // Add a straight line from the last midpoint to the end
        const finishFrom = waypoints[waypoints.length - 2];
        const finishTo = waypoints[waypoints.length - 1];
        Vec2.appendLineNoStartEnd(
            curve, Vec2.midpoint(finishFrom, finishTo), finishTo, resolution,
        );
        curve.push(finishTo);

        // De-duplicate close points.
        const deduplicatedCurve: Vec2[] = [curve[0]];

        let prev = curve[0];
        const distThreshSq = (resolution / 2) * (resolution / 2);
        for (let index = 1; index < curve.length; ++index) {
            const curr = curve[index];

            // Skip the point if it is too close to the previous point.
            if (curr.distSquared(prev) < distThreshSq)
                continue;

            deduplicatedCurve.push(curr);
            prev = curr;
        }
        return deduplicatedCurve;
    }

    /**
     * The Ramer-Douglas-Peucker algorithm.
     */
    static simplifyLine(
        points: Vec2[],
        epsilon: number,
    ): Vec2[] {
        let maxDist = 0;
        let maxIndex = 0;

        const endIndex = points.length - 1;
        const start = points[0];
        const end = points[endIndex];

        for (let index = 1; index < endIndex; index++) {
            const point = points[index];
            const dist = point.perpendicularDist(start, end);
            if (dist > maxDist) {
                maxIndex = index;
                maxDist = dist;
            }
        }
        if (maxDist <= epsilon)
            return [start, end];

        const left = Vec2.simplifyLine(points.slice(0, maxIndex + 1), epsilon);
        const right = Vec2.simplifyLine(points.slice(maxIndex), epsilon);
        return [...left.slice(0, left.length - 1), ...right];
    }
}
