import { Controller } from "@/ts/business/game/controller/Controller";
import { GameDirective } from "@/ts/business/game/controller/GameDirective";
import { PlayerStateController } from "@/ts/business/game/controller/playerstate/PlayerStateController";
import { DiceController } from "@/ts/business/game/controller/dice/DiceController";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";
import { DiceDirective } from "@/ts/business/game/controller/dice/DiceDirective";
import { BoardController } from "@/ts/business/game/controller/board/BoardController";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { Directive } from "@/ts/business/game/controller/Directive";
import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { Game } from "@/ts/royalur/Game";
import { Rune } from "@/ts/business/Rune";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { ReactionController } from "@/ts/business/game/controller/reactions/ReactionController";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { Optional } from "@/ts/util/Optional";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { RuneWithEvents } from "@/ts/business/RuneWithEvents";


export type WinListener = () => void;


/**
 * Coordinates the interactions within a game.
 */
export abstract class GameController extends Controller<GameDirective> {
    protected readonly playerStateController: PlayerStateController;
    protected readonly reactionController: ReactionController;
    protected readonly diceController: DiceController;
    protected readonly boardController: BoardController;
    protected readonly source: GameSource<LobbyStatus>;
    protected readonly theme: Rune<GameThemeType>;

    private playerStateDirectiveSinks: number = 0;
    private diceDirectiveSinks: number = 0;
    private boardDirectiveSinks: number = 0;

    private processingNewGameDirective: boolean = false;

    protected constructor(
        playerStateController: PlayerStateController,
        reactionController: ReactionController,
        diceController: DiceController,
        boardController: BoardController,
        source: GameSource<any>,
        defaultThemeType: GameThemeType,
    ) {
        super(source.getControllerMaxDirectives());

        this.playerStateController = playerStateController;
        this.reactionController = reactionController;
        this.diceController = diceController;
        this.boardController = boardController;
        this.source = source;
        this.theme = new Rune<GameThemeType>(defaultThemeType);
    }

    /**
     * Must be called at the end of the constructor of subclasses.
     */
    postConstructor() {
        // Has to be called because the self-listener for
        // the directive change hasn't been added yet.
        this.processNewGameDirective(this.getActiveDirective());
    }

    getGameSource(): GameSource<LobbyStatus> {
        return this.source;
    }

    getTheme(): Rune<GameThemeType> {
        return this.theme;
    }

    getGame(): RuneWithEvents<Game, GameUpdateEvent> {
        return this.source.game;
    }

    getLobbyStatus(): Rune<LobbyStatus> {
        return this.source.lobbyStatus;
    }

    getPlayers(): Rune<GamePlayers> {
        return this.source.players;
    }

    getError(): Rune<Optional<ClientError>> {
        return this.source.error;
    }

    override setup(): () => void {
        const cleanup: (() => void)[] = [];

        // This controller reacts to itself.
        cleanup.push(this.subscribeToActiveDirective(
            directive => this.processNewGameDirective(directive),
        ));

        cleanup.push(this.playerStateController.subscribeToActiveDirective(
            directive => this.onNewPlayerStateDirective(directive),
        ));
        cleanup.push(this.diceController.subscribeToActiveDirective(
            directive => this.onNewDiceDirective(directive),
        ));
        cleanup.push(this.boardController.subscribeToActiveDirective(
            directive => this.onNewBoardDirective(directive),
        ));

        cleanup.push(this.source.setup());

        return () => cleanup.forEach(fn => fn());
    }

    /**
     * If we have a directive sink, then we wait for its animations to complete.
     */
    registerPlayerStateDirectiveSink(): () => void {
        this.playerStateDirectiveSinks += 1;
        return () => {
            this.playerStateDirectiveSinks -= 1;
        };
    }

    /**
     * If we have a directive sink, then we wait for its animations to complete.
     */
    registerDiceDirectiveSink(): () => void {
        this.diceDirectiveSinks += 1;
        return () => {
            this.diceDirectiveSinks -= 1;
        };
    }

    /**
     * If we have a directive sink, then we wait for its animations to complete.
     */
    registerBoardDirectiveSink(): () => void {
        this.boardDirectiveSinks += 1;
        return () => {
            this.boardDirectiveSinks -= 1;
        };
    }

    override onDiscardedDirectives(directives: GameDirective[]): void {
        for (const directive of directives) {
            this.boardController.discardDirectives(directive.boardDirectives);
            this.diceController.discardDirectives(directive.diceDirectives);
            this.playerStateController.discardDirectives(directive.playerStateDirectives);
        }
    }

    /**
     * Translates game directives in this controller to child directives
     * for the board, dice, and player state.
     */
    private processNewGameDirective(directive: GameDirective) {
        this.processingNewGameDirective = true;
        try {
            this.boardController.pushDirectives(directive.boardDirectives);
            this.diceController.pushDirectives(directive.diceDirectives);
            this.playerStateController.pushDirectives(directive.playerStateDirectives);
        } finally {
            this.processingNewGameDirective = false;
            this.processFinishDirectiveCheck();
        }
    }

    addWinListener(_listener: WinListener): () => void {
        throw new Error("This game controller does not support addWinListener");
    }

    rematch() {
        throw new Error("This game controller does not support rematch");
    }

    cancelRematch() {
        throw new Error("This game controller does not support cancelRematch");
    }

    handleReaction(_player: PlayerType | null, _reaction: ReactionType) {
        throw new Error("This game controller does not support handleReaction");
    }

    handleAnimateDiceRolling() {
        throw new Error("This game controller does not support handleAnimateDiceRolling");
    }

    private isChildActiveOrQueued(childDirective: Directive): boolean {
        if (childDirective instanceof BoardDirective) {
            return (
                this.boardDirectiveSinks > 0
                && this.boardController.isActiveOrQueued(childDirective)
            );
        }
        if (childDirective instanceof DiceDirective) {
            return (
                this.diceDirectiveSinks > 0
                && this.diceController.isActiveOrQueued(childDirective)
            );
        }
        if (childDirective instanceof PlayerStateDirective) {
            return (
                this.playerStateDirectiveSinks > 0
                && this.playerStateController.isActiveOrQueued(childDirective)
            );
        }
        throw new Error("Unrecognised child directive " + JSON.stringify(childDirective));
    }

    private areActiveChildDirectivesRemaining(directive: GameDirective): boolean {
        for (const childDirective of directive.getAllDirectives()) {
            if (!childDirective.isLimboDirective() && this.isChildActiveOrQueued(childDirective))
                return true;
        }
        return false;
    }

    processFinishDirectiveCheck() {
        const directive = this.getActiveDirective();
        if (directive.isLimboDirective())
            return;

        if (!this.areActiveChildDirectivesRemaining(directive)) {
            this.popActiveDirective(directive);
        }
    }

    onNewPlayerStateDirective(_directive: PlayerStateDirective) {
        if (this.processingNewGameDirective)
            return;
        this.processFinishDirectiveCheck();
    }

    onNewDiceDirective(_directive: DiceDirective) {
        if (this.processingNewGameDirective)
            return;
        this.processFinishDirectiveCheck();
    }

    onNewBoardDirective(_directive: BoardDirective) {
        if (this.processingNewGameDirective)
            return;
        this.processFinishDirectiveCheck();
    }
}
