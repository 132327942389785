import { GameMode } from "@/ts/business/game/GameMode";
import { BotType } from "@/ts/business/game/BotType";
import { GameType } from "@/ts/business/game/GameType";
import { GameSettings } from "@/ts/royalur/model/GameSettings";


/**
 * The settings of a game that are chosen during setup.
 */
export abstract class LobbySettings {
    private readonly available: boolean;
    private readonly lobbyID: string | null;

    protected constructor(available: boolean, lobbyID: string | null) {
        this.available = available;
        this.lobbyID = lobbyID;
    }

    isAvailable(): boolean {
        return this.available;
    }

    getLobbyID(): string | null {
        return this.lobbyID;
    }

    abstract getMode(): GameMode;

    abstract getGameType(): GameType;

    abstract getGameSettings(): GameSettings;

    abstract getCustomGameSettings(): GameSettings;

    abstract getBotType(): BotType;

    isRemote(): boolean {
        const gameMode = this.getMode();
        if (gameMode.isRemote())
            return true;
        if (gameMode === GameMode.COMPUTER && this.getBotType().isOnline())
            return true;
        return false;
    }

    requiresAccount(): boolean {
        const gameType = this.getGameType();
        if (!gameType.hasSettings())
            return true;

        const mode = this.getMode();
        const difficulty = this.getBotType();
        return mode === GameMode.COMPUTER
            && (difficulty === BotType.HARD || difficulty === BotType.PANDA);
    }

    abstract toJSON(): string;

    abstract equals(other: LobbySettings): boolean;
}
