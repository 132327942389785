import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutJoinGame extends PacketOut {
    public static readonly SPECTATOR_KEY = "spectator";

    private readonly spectator: boolean;

    constructor(spectator: boolean) {
        super(OutboundPacketType.JOIN_GAME);
        this.spectator = spectator;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            [PacketOutJoinGame.SPECTATOR_KEY]: this.spectator,
        };
    }
}
