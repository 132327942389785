import { Controller } from "@/ts/business/game/controller/Controller";
import { DiceDirective } from "./DiceDirective";
import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { ListenerStore } from "@/ts/business/ListenerStore";


export type RollListener = (event: RollGameEvent) => void;

/**
 * Controls the rendering directives to give to the dice.
 */
export class DiceController extends Controller<DiceDirective> {
    private readonly rollListeners: ListenerStore<RollListener>;

    constructor() {
        super();
        this.rollListeners = new ListenerStore<RollListener>();
        this.pushDirectives(this.createWait(
            PlayerType.LIGHT,
        ));
    }

    override setup(): () => void {
        return () => {};
    }

    override onDiscardedDirectives(_directives: DiceDirective[]): void {
        // Nothing to do.
    }

    addRollListener(rollListener: RollListener): () => void {
        return this.rollListeners.add(rollListener);
    }

    handleRoll() {
        if (this.getActiveDirective().canBeRolled) {
            this.rollListeners.invoke(new RollGameEvent());
        }
    }

    createHidden(): DiceDirective[] {
        return [DiceDirective.hidden(
            this.assignNextDirectiveID(),
        )];
    }

    createRoll(
        activePlayer: PlayerType,
        roll: RoyalUrRoll | null,
        noMoves: boolean,
        endGame: boolean,
    ): DiceDirective[] {
        // If we are still waiting for the roll, just add a rolling limbo state.
        if (!roll) {
            return [DiceDirective.rollIndefinitely(
                this.assignNextDirectiveID(), activePlayer,
            )];
        }

        // Roll and select.
        return [
            DiceDirective.roll(
                this.assignNextDirectiveID(), activePlayer,
            ),
            DiceDirective.select(
                this.assignNextDirectiveID(),
                activePlayer, roll, noMoves, endGame,
            ),
            ...this.createRolled(activePlayer, roll),
        ];
    }

    createRolled(
        activePlayer: PlayerType,
        roll: RoyalUrRoll,
    ): DiceDirective[] {
        return [DiceDirective.selected(
            this.assignNextDirectiveID(), activePlayer, roll,
        )];
    }

    createWait(
        activePlayer: PlayerType,
    ): DiceDirective[] {
        return [DiceDirective.wait(
            this.assignNextDirectiveID(), activePlayer,
        )];
    }

    createWaitForRoll(
        activePlayer: PlayerType,
        roll?: RoyalUrRoll,
    ): DiceDirective[] {
        return [DiceDirective.waitForRoll(
            this.assignNextDirectiveID(), activePlayer, roll,
        )];
    }
}
