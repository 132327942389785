import { ImageAsset } from "@/app_components/assets/ImageAsset";
import { StaticImageData } from "next/image";
import { AudioAsset } from "@/app_components/assets/AudioAsset";
import { Howler, HowlOptions } from "howler";
import { Rune } from "@/ts/business/Rune";
import { AudioSettings } from "@/app_components/assets/AudioSettings";
import { MusicAsset } from "@/app_components/assets/MusicAsset";
import { MusicTrack } from "@/app_components/assets/MusicTrack";
import { Optional } from "@/ts/util/Optional";

/**
 * Manages the loading of assets for the Royal Game of Ur.
 */
export class AssetLoader {
    private static readonly RETRY_INTERVAL_MS = 20_000;

    private readonly isServerSide: boolean;

    private readonly imageAssets: Map<string, ImageAsset>;
    private readonly audioAssets: Map<string, AudioAsset>;
    private readonly musicAssets: Map<string, MusicAsset>;

    private readonly audioSettings: Rune<AudioSettings>;
    private readonly activeMusicTrack: Rune<Optional<MusicAsset>>;

    constructor() {
        this.isServerSide = (typeof window === "undefined");
        this.imageAssets = new Map();
        this.audioAssets = new Map();
        this.musicAssets = new Map();
        this.audioSettings = new Rune<AudioSettings>(AudioSettings.DEFAULT);
        this.audioSettings.subscribe(settings => this.applyAudioSettingsChange(settings));
        this.activeMusicTrack = new Rune<Optional<MusicAsset>>(Optional.empty());
        Howler.autoSuspend = false;
    }

    destroy() {
        for (const asset of this.audioAssets.values()) {
            asset.destroy();
        }
    }

    getAudioSettings(): Rune<AudioSettings> {
        return this.audioSettings;
    }

    getActiveMusicTrack(): Rune<Optional<MusicAsset>> {
        return this.activeMusicTrack;
    }

    private applyAudioSettingsChange(settings: AudioSettings) {
        for (const audioAsset of this.audioAssets.values()) {
            audioAsset.updateAudioSettings(settings);
        }
    }

    retryBrokenLoads() {
        for (const asset of this.imageAssets.values()) {
            if (asset.isLoaded())
                continue;

            const duration = Math.min(
                asset.getTimeSinceStartedLoadingMs(),
                asset.getTimeSinceError(),
            );
            if (duration > AssetLoader.RETRY_INTERVAL_MS) {
                asset.reset();
                asset.load();
            }
        }
        for (const asset of this.audioAssets.values()) {
            if (asset.isLoaded())
                continue;

            const duration = Math.min(
                asset.getTimeSinceStartedLoadingMs(),
                asset.getTimeSinceError(),
            );
            if (duration > AssetLoader.RETRY_INTERVAL_MS) {
                asset.reset();
                asset.load();
            }
        }
    }

    loadImages(imageData: StaticImageData[]): ImageAsset[] {
        const assets = [];
        for (const data of imageData) {
            assets.push(this.loadImage(data));
        }
        return assets;
    }

    loadImage(imageData: StaticImageData): ImageAsset {
        const existingImageAsset = this.imageAssets.get(imageData.src);
        if (existingImageAsset !== undefined)
            return existingImageAsset;

        const imageAsset = new ImageAsset(imageData);
        this.imageAssets.set(imageData.src, imageAsset);
        if (!this.isServerSide) {
            imageAsset.load();
        }
        return imageAsset;
    }

    loadAudioSet(
        sourceSets: string[][],
        options: Omit<HowlOptions, "src"> | null = null,
    ): AudioAsset[] {
        const assets = [];
        for (const sources of sourceSets) {
            assets.push(this.loadAudio(sources, options));
        }
        return assets;
    }

    loadAudio(
        sources: string[],
        options: Omit<HowlOptions, "src"> | null = null,
        isMusic?: boolean,
    ): AudioAsset {
        const existingAudioAsset = this.audioAssets.get(sources[0]);
        if (existingAudioAsset !== undefined)
            return existingAudioAsset;

        options = (options === null ? {} : options);
        const audioAsset = new AudioAsset(!!isMusic, sources, options, this.audioSettings.get());
        this.audioAssets.set(sources[0], audioAsset);
        if (!this.isServerSide) {
            audioAsset.load();
        }
        return audioAsset;
    }

    loadMusicTracks(
        tracks: MusicTrack[],
        options: Omit<HowlOptions, "src"> | null = null,
    ): MusicAsset[] {
        const assets = [];
        for (const track of tracks) {
            assets.push(this.loadMusicTrack(track, options));
        }
        return assets;
    }

    loadMusicTrack(
        track: MusicTrack,
        options: Omit<HowlOptions, "src"> | null = null,
    ): MusicAsset {
        const sources = track.sources;
        const existingMusicAsset = this.musicAssets.get(sources[0]);
        if (existingMusicAsset !== undefined)
            return existingMusicAsset;

        const audio = this.loadAudio(sources, options, true);
        const music = new MusicAsset(track, audio, this.activeMusicTrack);
        this.musicAssets.set(sources[0], music);
        return music;
    }
}
