import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";
import { ReactionType } from "@/ts/business/game/ReactionType";


export class PacketOutReaction extends PacketOut {
    private readonly reaction: ReactionType;

    constructor(reaction: ReactionType) {
        super(OutboundPacketType.REACTION);
        this.reaction = reaction;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            reaction_id: this.reaction.getID(),
        };
    }
}
