import { Board } from "@/ts/royalur/model/Board";
import { Move } from "@/ts/royalur/model/Move";
import { RuleSet } from "@/ts/royalur/rules/RuleSet";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A directive for rendering the dice.
 */
export class MoveBoardDirective extends BoardDirective {
    readonly board: Board;
    readonly move: Move;
    readonly moveWinsGame: boolean;
    readonly isGameWon: boolean;

    constructor(
        directiveID: number,
        rules: RuleSet,
        moveIndex: number,
        board: Board,
        move: Move,
        moveWinsGame: boolean,
        isGameWon: boolean,
    ) {
        super(directiveID, rules, moveIndex);
        this.board = board;
        this.move = move;
        this.moveWinsGame = moveWinsGame;
        this.isGameWon = isGameWon;
    }

    isLimboDirective(): boolean {
        return false;
    }

    override getBoard(): Board {
        return this.board;
    }

    override getActivePlayer(): PlayerType {
        return this.move.getPlayer();
    }
}
