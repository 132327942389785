

function renderResource(
    width: number,
    height: number,
    renderFn: (ctx: CanvasRenderingContext2D, width: number, height: number) => void,
) {
    if (isNaN(width) || isNaN(height))
        throw new Error("Width and height cannot be NaN: " + width + " x " + height);

    width = Math.max(1, width);
    height = Math.max(1, height);

    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    if (!ctx)
        throw new Error("Canvas is missing 2D context!");

    canvas.width = width;
    canvas.height = height;
    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";
    renderFn(ctx, width, height);
    return canvas;
}


export abstract class RenderedAsset {
    private canvas: HTMLCanvasElement | null = null;
    private width: number | null = null;
    private height: number | null = null;

    abstract shouldRedraw(width: number, height: number): boolean;

    abstract render(ctx: CanvasRenderingContext2D, width: number, height: number): void;

    clearCache(): void {
        this.canvas = null;
        this.width = null;
        this.height = null;
    }

    get(width: number, height: number): HTMLCanvasElement {
        width = Math.round(width);
        height = Math.round(height);

        if (this.canvas !== null) {
            if (this.shouldRedraw(width, height)) {
                this.canvas = null;
            } else if (width === this.width && height === this.height) {
                return this.canvas;
            }
        }

        this.canvas = renderResource(
            width, height,
            ctx => this.render(ctx, width, height),
        );
        this.width = width;
        this.height = height;
        return this.canvas;
    }

    draw(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number) {
        const boardImage = this.get(width, height);
        ctx.drawImage(boardImage, x, y, width, height);
    }
}
