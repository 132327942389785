import backgroundTile from "@/res/game/halloween/background_tile.png";
import lightPieceSelected from "@/res/game/halloween/light_piece_selected.png";
import lightPieceUnselected from "@/res/game/halloween/light_piece_unselected.png";
import darkPieceSelected from "@/res/game/halloween/dark_piece_selected.png";
import darkPieceUnselected from "@/res/game/halloween/dark_piece_unselected.png";
import board from "@/res/game/halloween/board.png";
import boardJSON from "@/res/game/halloween/board.json";
import diceUp1 from "@/res/game/halloween/dice/up1.png";
import diceUp2 from "@/res/game/halloween/dice/up2.png";
import diceUp3 from "@/res/game/halloween/dice/up3.png";
import diceDown1 from "@/res/game/halloween/dice/down1.png";
import diceDown2 from "@/res/game/halloween/dice/down2.png";
import diceDown3 from "@/res/game/halloween/dice/down3.png";
import selectedShadow from "@/res/game/halloween/dice/selected_shadow.png";
import unselectedShadow from "@/res/game/halloween/dice/unselected_shadow.png";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { BoardSpecification, GameThemeSettings } from "@/app_components/game/theme/GameThemeSettings";


export class HalloweenGameThemeSettings extends GameThemeSettings {
    constructor() {
        super();
        this.themeType = GameThemeType.HALLOWEEN;
        this.backgroundTile = backgroundTile;
        this.lightPieceSelected = lightPieceSelected;
        this.lightPieceUnselected = lightPieceUnselected;
        this.darkPieceSelected = darkPieceSelected;
        this.darkPieceUnselected = darkPieceUnselected;
        this.board = board;
        this.boardJSON = boardJSON as BoardSpecification;
        this.diceUpImages = [diceUp1, diceUp2, diceUp3];
        this.diceDownImages = [diceDown1, diceDown2, diceDown3];
        this.diceSelectedShadow = selectedShadow;
        this.diceUnselectedShadow = unselectedShadow;
    }

    getFireworkColour(): { r: number; g: number; b: number } {
        return Math.random() > 0.5 ? {
            r: 66, g: 101, b: 255,
        } : {
            r: 255, g: 200, b: 36,
        };
    }
}
