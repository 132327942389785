
export class AudioSettings {
    public static readonly DEFAULT: AudioSettings = new AudioSettings(0, 0, false);

    public readonly soundVolume: number;
    public readonly musicVolume: number;
    public readonly musicDisabled: boolean;

    constructor(soundVolume: number, musicVolume: number, musicDisabled: boolean) {
        this.soundVolume = soundVolume;
        this.musicVolume = musicVolume;
        this.musicDisabled = musicDisabled;
    }

    getVolume(isMusic: boolean): number {
        return (isMusic ? (this.musicDisabled ? 0 : this.musicVolume) : this.soundVolume);
    }
}
