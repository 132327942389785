

export interface LinkWithText {
    url: string;
    linkText: string;
}


export class MusicTrack {
    readonly title: string;
    readonly author: string;
    readonly links: LinkWithText[];
    readonly sources: string[];

    constructor(
        title: string,
        author: string,
        links: LinkWithText[],
        sources: string[],
    ) {
        this.title = title;
        this.author = author;
        this.links = links;
        this.sources = sources;
    }
}
