import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutOpen extends PacketOut {
    public static readonly PROTOCOL_VERSION_KEY = "protocol_version";
    public static readonly NAME_KEY = "name";

    private readonly protocolVersion: number;
    private readonly name: string;

    constructor(protocolVersion: number, name: string) {
        super(OutboundPacketType.OPEN);
        this.protocolVersion = protocolVersion;
        this.name = name;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            [PacketOutOpen.PROTOCOL_VERSION_KEY]: this.protocolVersion,
            [PacketOutOpen.NAME_KEY]: this.name,
        };
    }
}
