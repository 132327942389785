import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutResign extends PacketOut {

    constructor() {
        super(OutboundPacketType.RESIGN);
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {};
    }
}
