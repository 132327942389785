import { StaticImageData } from "next/image";
import { getTimeMs, getTimeSeconds, renderResource } from "@/ts/util/utils";
import { Milliseconds } from "@/ts/util/units";


export class ImageAsset {
    public static readonly MAX_SCALE_DOWNS = 4;

    private readonly src: string;
    private readonly width: number;
    private readonly height: number;
    private image: HTMLImageElement | null = null;

    private startLoadingTimeMs: Milliseconds | null = null;
    private errorTimeMs: Milliseconds | null = null;

    private readonly scaledVersions: HTMLCanvasElement[] = [];

    constructor(imageData: StaticImageData) {
        this.src = imageData.src;
        this.width = imageData.width;
        this.height = imageData.height;
        this.reset();
    }

    reset() {
        this.image = null;
        this.startLoadingTimeMs = null;
        this.errorTimeMs = null;
        this.scaledVersions.length = 0;
    }

    getWidth(): number {
        return this.width;
    }

    getHeight(): number {
        return this.height;
    }

    hasStartedLoading(): boolean {
        return this.image !== null;
    }

    isLoaded(): boolean {
        const image = this.image;
        return image !== null && image.complete && image.naturalWidth !== 0;
    }

    isErrored(): boolean {
        if (this.errorTimeMs !== null)
            return true;

        const image = this.image;
        return image !== null && image.complete && image.naturalWidth === 0;
    }

    getTimeSinceStartedLoadingMs(): number {
        if (this.startLoadingTimeMs === null)
            return -1;

        return getTimeMs() - this.startLoadingTimeMs;
    }

    getTimeSinceError(): number {
        if (this.errorTimeMs === null)
            return -1;

        return getTimeMs() - this.errorTimeMs;
    }

    load() {
        if (this.image !== null)
            throw new Error("Already started loading!");

        // Ignore loading if we are running server-side.
        if (typeof Image === "undefined")
            return;

        const image = new Image();
        this.image = image;
        this.startLoadingTimeMs = getTimeMs();

        image.onerror = (event) => {
            if (image === this.image) {
                this.onError(event);
            }
        };
        image.src = this.src;
    }

    getRawImage(): HTMLImageElement {
        if (this.image === null || !this.isLoaded())
            throw new Error("Image not loaded");

        return this.image;
    }

    getScaledVersion(targetWidth: number): HTMLImageElement | HTMLCanvasElement {
        if (targetWidth <= 0)
            throw new Error("Width must be positive: " + targetWidth);

        const raw = this.getRawImage();

        // Determine how many times to halve the size of the image.
        let width = raw.width;
        let scaleDowns = 0;
        do {
            const nextWidth = Math.round(width / 2);
            if (nextWidth < targetWidth)
                break;

            scaleDowns += 1;
            width = nextWidth;

            if (this.scaledVersions.length < scaleDowns) {
                const last = (scaleDowns === 1 ? raw : this.scaledVersions[scaleDowns - 2]);
                const scaledWidth = Math.round(last.width / 2);
                const scaledHeight = Math.round(last.height / 2);

                this.scaledVersions.push(renderResource(scaledWidth, scaledHeight, (ctx) => {
                    ctx.drawImage(last, 0, 0, scaledWidth, scaledHeight);
                }));
            }
        } while (scaleDowns < ImageAsset.MAX_SCALE_DOWNS);

        return (scaleDowns === 0 ? raw : this.scaledVersions[scaleDowns - 1]);
    }

    draw(
        ctx: CanvasRenderingContext2D,
        left: number,
        top: number,
        width?: number,
        height?: number,
    ) {
        width ??= this.width;
        height ??= this.height;

        const errored = this.isErrored();
        if (!this.isLoaded() || errored) {
            ctx.save();
            try {
                ctx.globalAlpha = 0.4;
                const v = 30 + 10 * Math.sin(getTimeSeconds() * (2 * Math.PI));
                ctx.fillStyle = (errored ? "#bf1f1f" : `rgb(${v}, ${v}, ${v})`);
                ctx.fillRect(left, top, width, height);
            } finally {
                ctx.restore();
            }
            return;
        }

        ctx.drawImage(
            this.getScaledVersion(width),
            left, top, width, height,
        );
    }

    private onError(event: string | Event) {
        this.errorTimeMs = getTimeMs();
        console.error("Error loading " + this.src + ": " + JSON.stringify(event));
    }
}
