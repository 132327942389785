import { Directive } from "@/ts/business/game/controller/Directive";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { ReactionType } from "@/ts/business/game/ReactionType";


/**
 * A directive for showing a reaction.
 */
export class ReactionDirective extends Directive {
    readonly player: PlayerType | null;
    readonly reaction: ReactionType | null;

    constructor(
        directiveID: number,
        player: PlayerType | null,
        reaction: ReactionType | null,
    ) {
        super(directiveID);
        this.player = player;
        this.reaction = reaction;
    }

    isLimboDirective(): boolean {
        return this.reaction === null;
    }

    getPlayer(): PlayerType | null {
        return this.player;
    }

    getReaction(): ReactionType | null {
        return this.reaction;
    }
}
