"use client";

import { createContext, ReactNode, useContext, useEffect, useRef } from "react";
import { AssetLoader } from "@/app_components/assets/AssetLoader";


const AssetContext = createContext<AssetLoader | null>(null);

export function useAssetLoader(): AssetLoader {
    const assetLoader = useContext(AssetContext);
    if (assetLoader === null)
        throw new Error("Missing asset loader!");

    return assetLoader;
}


interface AssetProviderProps {
    children: ReactNode;
}

export function AssetProvider({ children }: AssetProviderProps) {
    const assetLoaderRef = useRef<AssetLoader | null>(null);
    if (assetLoaderRef.current === null) {
        assetLoaderRef.current = new AssetLoader();
    }

    useEffect(() => {
        const assetLoader = assetLoaderRef.current;
        if (!assetLoader)
            return;

        return () => assetLoader.destroy();
    }, [assetLoaderRef]);

    // Retry if an image fails to load.
    useEffect(() => {
        const intervalID = setInterval(() => {
            const assetLoader = assetLoaderRef.current;
            if (assetLoader) {
                assetLoader.retryBrokenLoads();
            }
        }, 1000);

        return () => {
            clearInterval(intervalID);
        };
    }, [assetLoaderRef]);

    return (
        <AssetContext.Provider value={assetLoaderRef.current}>
            {children}
        </AssetContext.Provider>
    );
}
