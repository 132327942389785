import { RenderedAsset } from "@/app_components/game/render/RenderedAsset";
import { ImageAsset } from "@/app_components/assets/ImageAsset";
import { Rectangle } from "@/ts/util/Rectangle";
import { Tile } from "@/ts/royalur/model/Tile";
import { Vec2 } from "@/ts/util/Vec2";
import { BoardShape } from "@/ts/royalur/model/shape/BoardShape";
import { clamp, max } from "@/ts/util/numbers";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Rune } from "@/ts/business/Rune";
import { Optional } from "@/ts/util/Optional";


export class BoardAsset extends RenderedAsset {
    static readonly TILE_CLICK_THRESHOLD: number = 0.04;

    private readonly boardShape: BoardShape;
    private readonly boardImage: ImageAsset;
    private readonly tileBoundRatios: [number, number, number, number][];
    private readonly tileWidthRatio: number;

    public readonly boardBounds: Rune<Optional<Rectangle>> = new Rune(Optional.empty());
    private lastBoardLoaded: boolean = false;

    constructor(
        boardShape: BoardShape,
        boardImage: ImageAsset,
        tileBoundRatios: [number, number, number, number][],
    ) {
        super();
        this.boardShape = boardShape;
        this.boardImage = boardImage;
        this.tileBoundRatios = tileBoundRatios;

        let tileWidthSum = 0;
        for (const tileBounds of tileBoundRatios) {
            tileWidthSum += tileBounds[2];
        }
        this.tileWidthRatio = tileWidthSum / tileBoundRatios.length;
    }

    hasBeenRendered(): boolean {
        return this.boardBounds.get().isPresent();
    }

    getBoardBounds(): Rectangle {
        const bounds = this.boardBounds.get();
        if (bounds.isEmpty())
            throw new Error("The board has not been rendered yet");

        return bounds.get();
    }

    calculateTileBounds(
        boardBounds: Rectangle,
        tile: Tile,
        leftPlayer: PlayerType
    ): Rectangle {
        let ix = tile.getXIndex();
        const iy = tile.getYIndex();

        const boardShape = this.boardShape;
        const tilesX = boardShape.getWidth();
        const tilesY = boardShape.getHeight();
        if (ix < 0 || ix >= tilesX || iy < 0 || iy >= tilesY) {
            // Extrapolate from the nearest tile.
            const rect = this.calculateTileBounds(
                boardBounds,
                Tile.fromIndices(
                    clamp(ix, 0, tilesX - 1),
                    clamp(iy, 0, tilesY - 1),
                ),
                leftPlayer,
            );
            const dx = rect.getWidth() * (ix < 0 ? ix : (ix >= tilesX ? ix - tilesX + 1 : 0));
            const dy = rect.getHeight() * (iy < 0 ? iy : (iy >= tilesY ? iy - tilesY + 1 : 0));
            return new Rectangle(
                rect.left + dx,
                rect.top + dy,
                rect.right + dx,
                rect.bottom + dy,
            );
        }

        // Flip the board horizontally if the dark player is on the left.
        if (leftPlayer === PlayerType.DARK) {
            ix = this.boardShape.getWidth() - ix - 1;
        }

        const index = ix + iy * tilesX;
        const [xRatio, yRatio, widthRatio, heightRatio] = this.tileBoundRatios[index];

        const tileX = boardBounds.left + xRatio * boardBounds.getWidth();
        const tileY = boardBounds.top + yRatio * boardBounds.getHeight();
        return new Rectangle(
            tileX, tileY,
            tileX + widthRatio * boardBounds.getWidth(),
            tileY + heightRatio * boardBounds.getHeight(),
        );
    }

    getTileBounds(tile: Tile, leftPlayer: PlayerType): Rectangle {
        const boardBounds = this.getBoardBounds();
        return this.calculateTileBounds(boardBounds, tile, leftPlayer);
    }

    getTileWidth(): number {
        return this.tileWidthRatio * this.getBoardBounds().getWidth();
    }

    getTileAt(location: Vec2, leftPlayer: PlayerType): Tile | null {
        const boardBounds = this.getBoardBounds();
        const distThreshold = BoardAsset.TILE_CLICK_THRESHOLD;
        const distSqThreshold = distThreshold * distThreshold;

        const x = (location.x - boardBounds.left) / boardBounds.getWidth();
        const y = (location.y - boardBounds.top) / boardBounds.getHeight();

        let bestTileIndex: number | null = null;
        let bestTileDistSq: number | null = null;

        for (let index = 0; index < this.tileBoundRatios.length; ++index) {
            const [left, top, boundsWidth, boundsHeight] = this.tileBoundRatios[index];
            const right = left + boundsWidth;
            const bottom = top + boundsHeight;
            if (x >= left && y >= top && x <= right && y <= bottom) {
                bestTileIndex = index;
                bestTileDistSq = 0;
                break;
            }

            const dx = max(max(0, left - x), max(0, x - right));
            const dy = max(max(0, top - y), max(0, y - bottom));
            const distSq = dx * dx + dy * dy;
            if (distSq <= distSqThreshold && (bestTileDistSq === null || distSq < bestTileDistSq)) {
                bestTileIndex = index;
                bestTileDistSq = distSq;
            }
        }
        if (bestTileIndex === null)
            return null;

        const tilesAcross = this.boardShape.getWidth();
        let ix = bestTileIndex % tilesAcross;
        const iy = Math.floor(bestTileIndex / tilesAcross);

        // Flip the board horizontally if the dark player is on the left.
        if (leftPlayer === PlayerType.DARK) {
            ix = tilesAcross - ix - 1;
        }
        return Tile.fromIndices(ix, iy);
    }

    private static calcBoardSize(
        width: number,
        height: number,
        boardImage: ImageAsset,
    ): { boardWidth: number; boardHeight: number } {
        const useRatio = 0.9;
        const imWidth = boardImage.getWidth();
        const imHeight = boardImage.getHeight();

        // Calculate to maximise height;
        const boardHeight1 = useRatio * height;
        const boardWidth1 = boardHeight1 / imHeight * imWidth;

        // Calculate to maximise width
        const boardWidth2 = useRatio * width;
        const boardHeight2 = boardWidth2 / imWidth * imHeight;

        const use1 = (boardWidth1 < width * useRatio);
        return {
            boardWidth: (use1 ? boardWidth1 : boardWidth2),
            boardHeight: (use1 ? boardHeight1 : boardHeight2),
        };
    }

    shouldRedraw(): boolean {
        return this.lastBoardLoaded !== this.boardImage.isLoaded();
    }

    render(ctx: CanvasRenderingContext2D, width: number, height: number): void {
        const loaded = this.boardImage.isLoaded();
        this.lastBoardLoaded = loaded;

        const { boardWidth, boardHeight } = BoardAsset.calcBoardSize(
            width, height, this.boardImage,
        );

        const boardLeft = (width - boardWidth) / 2;
        const boardTop = (height - boardHeight) / 2;
        const boardRight = boardLeft + boardWidth;
        const boardBottom = boardTop + boardHeight;
        const bounds = new Rectangle(boardLeft, boardTop, boardRight, boardBottom);

        // Only update if we need to, as this reference is used for cache dependencies.
        const currentBounds = this.boardBounds.get();
        if (currentBounds.isEmpty() || !bounds.equals(currentBounds.get())) {
            this.boardBounds.set(Optional.of(bounds), currentBounds);
        }

        if (loaded) {
            ctx.save();
            try {
                ctx.shadowColor = "black";
                ctx.shadowBlur = 15;
                this.boardImage.draw(
                    ctx, boardLeft, boardTop,
                    boardWidth, boardHeight,
                );
            } finally {
                ctx.restore();
            }
        }
    }
}
