import { AudioAsset } from "@/app_components/assets/AudioAsset";
import { MusicTrack } from "@/app_components/assets/MusicTrack";
import { Rune } from "@/ts/business/Rune";
import { Optional } from "@/ts/util/Optional";


export class MusicAsset {

    private readonly track: MusicTrack;
    private readonly audio: AudioAsset;
    private readonly activeTrack: Rune<Optional<MusicAsset>>;
    private readonly playing: Rune<boolean>;

    constructor(
        track: MusicTrack,
        audio: AudioAsset,
        activeTrack: Rune<Optional<MusicAsset>>,
    ) {
        this.track = track;
        this.audio = audio;
        this.activeTrack = activeTrack;
        this.playing = new Rune<boolean>(false);
        this.audio.addPlayingListener((playing, paused) => {
            this.playing.set(playing);

            if (playing || paused) {
                this.activeTrack.set(Optional.of(this));
            } else {
                const current = this.activeTrack.get();
                if (current.isPresent() && current.get() === this) {
                    this.activeTrack.set(Optional.empty(), current);
                }
            }
        });
    }

    getTrack(): MusicTrack {
        return this.track;
    }

    getPlayingRune(): Rune<boolean> {
        return this.playing;
    }

    isPlaying(): boolean {
        return this.audio.isPlaying();
    }

    tryPlay(): boolean {
        if (this.audio.isPlaying())
            return true;
        if (!this.audio.isLoaded())
            return false;

        return !isNaN(this.audio.play());
    }

    pause() {
        this.audio.pause();
    }
}
