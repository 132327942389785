import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";
import { BotType } from "@/ts/business/game/BotType";
import { LobbySettings } from "@/ts/business/game/LobbySettings";
import { GameSettings } from "@/ts/royalur/model/GameSettings";
import { isJsonDict, readJsonEnumOrElse, readNullableJsonDict } from "@/ts/util/json";
import { RoyalUrJsonNotation } from "@/ts/business/game/royalur/RoyalUrJsonNotation";


/**
 * Used when we know all the settings of a game.
 */
export class KnownLobbySettings extends LobbySettings {
    static readonly DEFAULT = new KnownLobbySettings(
        GameMode.COMPUTER,
        GameType.FINKEL,
        GameType.FINKEL.getSettings(),
        BotType.EASY,
    );

    private readonly mode: GameMode;
    private readonly gameType: GameType;
    private readonly customGameSettings: GameSettings;
    private readonly botType: BotType;

    constructor(
        mode: GameMode,
        gameType: GameType,
        customGameSettings: GameSettings,
        botType: BotType,
    ) {
        super(true, null);
        this.mode = mode;
        this.gameType = gameType;
        this.customGameSettings = customGameSettings;
        this.botType = botType;
    }

    toJSON(): string {
        const jsonNotation = new RoyalUrJsonNotation();
        return JSON.stringify({
            mode: this.mode.getID(),
            gameSettings: this.gameType.getID(),
            customGameSettings: jsonNotation.writeGameSettings(this.customGameSettings),
            difficulty: this.botType.getID(),
        });
    }

    static fromJSON(json: string, defaultValues: KnownLobbySettings): KnownLobbySettings {
        try {
            const values = JSON.parse(json);
            if (!isJsonDict(values))
                return defaultValues;

            const mode: GameMode = readJsonEnumOrElse(
                values, "mode",
                GameMode.PARSING_MAP,
                defaultValues.getMode(),
            );

            const gameType: GameType = readJsonEnumOrElse(
                values, "gameSettings",
                GameType.PARSING_MAP,
                defaultValues.getGameType(),
            );

            let customGameSettings = defaultValues.getCustomGameSettings();
            const customGameSettingsJSON = readNullableJsonDict(values, "customGameSettings");
            if (customGameSettingsJSON) {
                const jsonNotation = new RoyalUrJsonNotation();
                customGameSettings = jsonNotation.readGameSettings(customGameSettingsJSON);
            }

            const difficulty: BotType = readJsonEnumOrElse(
                values, "difficulty",
                BotType.PARSING_MAP,
                defaultValues.getBotType(),
            );

            return new KnownLobbySettings(
                mode,
                gameType,
                customGameSettings,
                difficulty,
            );
        } catch (e) {
            console.error("Error parsing preferred game setup settings", e);
            return defaultValues;
        }
    }

    override getMode(): GameMode {
        return this.mode;
    }

    override getGameType(): GameType {
        return this.gameType;
    }

    override getGameSettings(): GameSettings {
        return (
            this.gameType.hasSettings()
                ? this.gameType.getSettings()
                : this.customGameSettings
        );
    }

    override getCustomGameSettings(): GameSettings {
        return this.customGameSettings;
    }

    override getBotType(): BotType {
        return this.botType;
    }

    withMode(mode: GameMode): KnownLobbySettings {
        return new KnownLobbySettings(
            mode, this.gameType, this.customGameSettings, this.botType,
        );
    }

    withGameType(gameType: GameType): KnownLobbySettings {
        return new KnownLobbySettings(
            this.mode, gameType, this.customGameSettings, this.botType,
        );
    }

    withCustomGameSettings(gameSettings: GameSettings): KnownLobbySettings {
        return new KnownLobbySettings(
            this.mode, this.gameType, gameSettings, this.botType,
        );
    }

    withBotType(botType: BotType): KnownLobbySettings {
        return new KnownLobbySettings(
            this.mode, this.gameType, this.customGameSettings, botType,
        );
    }

    override equals(other: LobbySettings): boolean {
        if (!(other instanceof KnownLobbySettings))
            return false;

        return this.mode === other.mode
            && this.gameType === other.gameType
            && this.botType === other.botType;
    }
}
