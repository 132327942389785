import { RoyalUrRoll } from "@/ts/business/game/royalur/RoyalUrRoll";
import { Directive } from "@/ts/business/game/controller/Directive";
import { PlayerType } from "@/ts/royalur/model/PlayerType";


/**
 * A directive for rendering the dice.
 */
export class DiceDirective extends Directive {
    readonly activePlayer: PlayerType | null;
    readonly canBeRolled: boolean;
    readonly rolling: boolean;
    readonly rollForever: boolean;
    readonly roll?: RoyalUrRoll;
    readonly noMoves: boolean;
    readonly endGame: boolean;

    constructor(
        directiveID: number,
        activePlayer: PlayerType | null,
        canBeRolled: boolean,
        rolling: boolean,
        rollForever: boolean,
        roll?: RoyalUrRoll,
        noMoves?: boolean,
        endGame?: boolean,
    ) {
        super(directiveID);

        if (canBeRolled && rolling)
            throw new Error("Dice cannot be rolled while rolling");

        this.activePlayer = activePlayer;
        this.canBeRolled = canBeRolled;
        this.rolling = rolling;
        this.rollForever = rollForever;
        this.roll = roll;
        this.noMoves = !!noMoves;
        this.endGame = !!endGame;
    }

    getValue(): number | undefined {
        return this.roll?.value();
    }

    isLimboDirective(): boolean {
        if (this.canBeRolled)
            return true;
        if (this.rolling && this.rollForever && this.roll === undefined)
            return true;
        return !this.rolling;
    }

    static hidden(directiveID: number): DiceDirective {
        return new DiceDirective(
            directiveID, null, false, false, false,
        );
    }

    static rollIndefinitely(
        directiveID: number,
        activePlayer: PlayerType,
    ): DiceDirective {
        return new DiceDirective(
            directiveID, activePlayer, false, true, true,
        );
    }

    static roll(
        directiveID: number,
        activePlayer: PlayerType,
    ): DiceDirective {
        return new DiceDirective(
            directiveID, activePlayer, false, true, false,
        );
    }

    static select(
        directiveID: number,
        activePlayer: PlayerType,
        roll: RoyalUrRoll,
        noMoves: boolean,
        endGame: boolean,
    ): DiceDirective {
        return new DiceDirective(
            directiveID, activePlayer, false, true, false,
            roll, noMoves, endGame,
        );
    }

    static selected(
        directiveID: number,
        activePlayer: PlayerType,
        roll: RoyalUrRoll,
    ): DiceDirective {
        return new DiceDirective(
            directiveID, activePlayer, false, false, false, roll,
        );
    }

    static wait(
        directiveID: number,
        activePlayer: PlayerType,
    ): DiceDirective {
        return new DiceDirective(
            directiveID, activePlayer, false, false, false,
        );
    }

    static waitForRoll(
        directiveID: number,
        activePlayer: PlayerType,
        roll?: RoyalUrRoll,
    ): DiceDirective {
        return new DiceDirective(
            directiveID, activePlayer, true, false, false, roll,
        );
    }
}
