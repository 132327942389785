import woodBrownBg from "@/res/backgrounds/wood_brown.png";
import lightPieceSelected from "@/res/game/holiday/green_piece_selected.png";
import lightPieceUnselected from "@/res/game/holiday/green_piece_unselected.png";
import darkPieceSelected from "@/res/game/holiday/red_piece_selected.png";
import darkPieceUnselected from "@/res/game/holiday/red_piece_unselected.png";
import board from "@/res/game/holiday/board.png";
import boardJSON from "@/res/game/holiday/board.json";
import diceUp1 from "@/res/game/holiday/dice/up1.png";
import diceUp2 from "@/res/game/holiday/dice/up2.png";
import diceUp3 from "@/res/game/holiday/dice/up3.png";
import diceDown1 from "@/res/game/holiday/dice/down1.png";
import diceDown2 from "@/res/game/holiday/dice/down2.png";
import diceDown3 from "@/res/game/holiday/dice/down3.png";
import selectedShadow from "@/res/game/holiday/dice/selected_shadow.png";
import unselectedShadow from "@/res/game/holiday/dice/unselected_shadow.png";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { BoardSpecification, GameThemeSettings } from "@/app_components/game/theme/GameThemeSettings";


export class HolidayGameThemeSettings extends GameThemeSettings {
    constructor() {
        super();
        this.themeType = GameThemeType.HOLIDAY;
        this.backgroundTile = woodBrownBg;
        this.lightPieceSelected = lightPieceSelected;
        this.lightPieceUnselected = lightPieceUnselected;
        this.darkPieceSelected = darkPieceSelected;
        this.darkPieceUnselected = darkPieceUnselected;
        this.board = board;
        this.boardJSON = boardJSON as BoardSpecification;
        this.diceUpImages = [diceUp1, diceUp2, diceUp3];
        this.diceDownImages = [diceDown1, diceDown2, diceDown3];
        this.diceSelectedShadow = selectedShadow;
        this.diceUnselectedShadow = unselectedShadow;
    }

    getFireworkColour(): { r: number; g: number; b: number } {
        return Math.random() > 0.5 ? {
            r: 47, g: 212, b: 58,
        } : {
            r: 255, g: 69, b: 91,
        };
    }
}
