import { Controller } from "@/ts/business/game/controller/Controller";
import { ReactionDirective } from "./ReactionDirective";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { ListenerStore } from "@/ts/business/ListenerStore";
import { Rune } from "@/ts/business/Rune";


export type ReactionListener = (player: PlayerType | null, reaction: ReactionType) => void;


export class ReactionController extends Controller<ReactionDirective> {
    private readonly reactionListeners: ListenerStore<ReactionListener>;
    private readonly playingOwnPlayerReaction: Rune<boolean>;

    constructor() {
        super();
        this.reactionListeners = new ListenerStore<ReactionListener>();
        this.playingOwnPlayerReaction = new Rune<boolean>(false);
        this.pushDirective(this.createEmpty());
    }

    override setup(): () => void {
        return () => {};
    }

    override onDiscardedDirectives(_directives: ReactionDirective[]): void {
        // Nothing to do.
    }

    getPlayingOwnPlayerReaction(): Rune<boolean> {
        return this.playingOwnPlayerReaction;
    }

    addReactionListener(reactionListener: ReactionListener): () => void {
        return this.reactionListeners.add(reactionListener);
    }

    handleReaction(player: PlayerType | null, reaction: ReactionType) {
        this.reactionListeners.invoke(player, reaction);
    }

    createEmpty(): ReactionDirective {
        return new ReactionDirective(
            this.assignNextDirectiveID(),
            null, null,
        );
    }

    createReaction(
        player: PlayerType | null,
        reaction: ReactionType,
    ): ReactionDirective[] {
        return [
            new ReactionDirective(
                this.assignNextDirectiveID(),
                player, reaction,
            ),
            this.createEmpty(),
        ];
    }
}
