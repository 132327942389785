import { Directive } from "@/ts/business/game/controller/Directive";
import { GameState } from "@/ts/royalur/rules/state/GameState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { BoardDirective } from "@/ts/business/game/controller/board/BoardDirective";
import { DiceDirective } from "@/ts/business/game/controller/dice/DiceDirective";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";


/**
 * A directive for rendering the game.
 */
export class GameDirective extends Directive {
    readonly state: GameState;
    readonly activePlayer: PlayerType;

    readonly boardDirectives: BoardDirective[];
    readonly diceDirectives: DiceDirective[];
    readonly playerStateDirectives: PlayerStateDirective[];

    constructor(
        directiveID: number,
        state: GameState,
        boardDirectives: BoardDirective[],
        diceDirectives: DiceDirective[],
        playerStateDirectives: PlayerStateDirective[],
    ) {
        super(directiveID);
        if (boardDirectives.length === 0)
            throw new Error("No board directives were supplied");
        if (diceDirectives.length === 0)
            throw new Error("No dice directives were supplied");
        if (playerStateDirectives.length === 0)
            throw new Error("No player state directives were supplied");

        this.state = state;
        this.activePlayer = state.getSubject() ?? PlayerType.LIGHT;
        this.boardDirectives = boardDirectives;
        this.diceDirectives = diceDirectives;
        this.playerStateDirectives = playerStateDirectives;
    }

    getAllDirectives(): Directive[] {
        return [
            ...this.boardDirectives,
            ...this.diceDirectives,
            ...this.playerStateDirectives,
        ];
    }

    isLimboDirective(): boolean {
        for (const directive of this.getAllDirectives()) {
            if (!directive.isLimboDirective())
                return false;
        }
        return true;
    }
}
