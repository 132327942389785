

/**
 * An event that is emitted to the game controller when a game is updated,
 * to inform the controller of how to animate the new game states.
 */
export class GameUpdateEvent {
    private readonly skipAnimation: boolean;

    constructor(skipAnimation: boolean) {
        this.skipAnimation = skipAnimation;
    }

    shouldSkipAnimation(): boolean {
        return this.skipAnimation;
    }
}
