import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutRoll extends PacketOut {
    constructor() {
        super(OutboundPacketType.ROLL);
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {};
    }
}
