

/**
 * An instruction to a rendered component.
 */
export abstract class Directive {
    private readonly directiveID: number;

    protected constructor(directiveID: number) {
        this.directiveID = directiveID;
    }

    abstract isLimboDirective(): boolean;

    getDirectiveID(): number {
        return this.directiveID;
    }
}
