import { Howl, HowlOptions } from "howler";
import { AudioSettings } from "@/app_components/assets/AudioSettings";
import { Milliseconds } from "@/ts/util/units";
import { getTimeMs } from "@/ts/util/utils";
import { ListenerStore } from "@/ts/business/ListenerStore";


function captureAudioErrors<T>(task: () => T, defaultValue: T): T {
    try {
        return task();
    } catch (error) {
        // This error can occur on iOS when they disable audio or the audio
        // is interrupted by Siri. Therefore, we're happy to ignore it.
        if (typeof error === "object" && error !== null
            && (error as any).message === "Failed to start the audio device") {

            return defaultValue;
        }
        console.error(error);
        return defaultValue;
    }
}


function captureAudioErrorsWithoutReturn(task: () => void) {
    captureAudioErrors(task, null);
}


export type AudioPlayingListener = (playing: boolean, paused: boolean) => void;


export class AudioAsset {
    private readonly isMusic: boolean;
    private readonly sources: string[];
    private readonly options: Omit<HowlOptions, "src">;

    private howl: Howl | null = null;
    private settings: AudioSettings;

    private lastPlaying: boolean = false;
    private lastPaused: boolean = false;
    private readonly playingListeners: ListenerStore<AudioPlayingListener>
        = new ListenerStore<AudioPlayingListener>();

    private startLoadingTimeMs: Milliseconds | null = null;
    private errorTimeMs: Milliseconds | null = null;

    constructor(
        isMusic: boolean,
        sources: string[],
        options: Omit<HowlOptions, "src">,
        settings: AudioSettings,
    ) {
        this.isMusic = isMusic;
        this.sources = sources;
        this.options = options;
        this.settings = settings;
        this.reset();
    }

    private updatePlayingState(playing: boolean, paused: boolean) {
        if (this.lastPlaying === playing && this.lastPaused === paused)
            return;

        this.lastPlaying = playing;
        this.lastPaused = paused;
        this.playingListeners.invoke(playing, paused);
    }

    reset() {
        this.howl = null;
        this.startLoadingTimeMs = null;
        this.errorTimeMs = null;
        this.updatePlayingState(false, false);
    }

    destroy() {
        if (this.howl === null)
            return;

        this.howl.stop();
    }

    hasStartedLoading(): boolean {
        return this.howl !== null;
    }

    isLoaded(): boolean {
        return this.howl !== null && this.howl.state() === "loaded";
    }

    isPlaying(soundID?: number): boolean {
        return this.howl !== null && this.howl.playing(soundID);
    }

    addPlayingListener(listener: AudioPlayingListener): () => void {
        return this.playingListeners.add(listener);
    }

    isErrored(): boolean {
        return this.errorTimeMs !== null;
    }

    getTimeSinceStartedLoadingMs(): number {
        if (this.startLoadingTimeMs === null)
            return -1;

        return getTimeMs() - this.startLoadingTimeMs;
    }

    getTimeSinceError(): number {
        if (this.errorTimeMs === null)
            return -1;

        return getTimeMs() - this.errorTimeMs;
    }

    private getVolumeSetting(): number {
        return this.settings.getVolume(this.isMusic);
    }

    private applyAudioSettings() {
        captureAudioErrorsWithoutReturn(() => {
            if (this.howl === null)
                return;

            const vol = this.getVolumeSetting();
            this.howl.volume(vol);
            if (vol <= 0) {
                this.stop();
            }
        });
    }

    updateAudioSettings(settings: AudioSettings) {
        this.settings = settings;
        this.applyAudioSettings();
    }

    load() {
        captureAudioErrorsWithoutReturn(() => {
            if (this.hasStartedLoading())
                throw new Error("Already started loading!");

            const howl = new Howl({
                src: this.sources,
                ...this.options,
            });
            this.howl = howl;
            this.startLoadingTimeMs = getTimeMs();

            this.howl.on("loaderror", (_soundId: number, error: unknown) => {
                if (howl === this.howl) {
                    this.onError(error);
                }
            });
            this.howl.on("play", () => {
                this.updatePlayingState(true, false);
            });
            this.howl.on("stop", () => {
                this.updatePlayingState(false, false);
            });
            this.howl.on("end", () => {
                this.updatePlayingState(false, false);
            });
            this.howl.on("pause", () => {
                this.updatePlayingState(false, true);
            });
            this.howl.load();
            this.applyAudioSettings();
        });
    }

    /**
     * @return The sound ID.
     */
    play(waitForLoad: boolean = false, playVolume?: number): number {
        playVolume ??= 1.0;
        if (playVolume < 0.0 || playVolume > 1.0)
            throw new Error(`playVolume should fall between 0 and 1 inclusive, but it is ${playVolume}`);

        return captureAudioErrors(() => {
            if (this.howl === null)
                throw new Error("Audio hasn't started loading");

            const volumeSetting = this.getVolumeSetting();
            if (volumeSetting <= 0 || playVolume <= 0)
                return NaN;
            if (!waitForLoad && !this.isLoaded())
                return NaN;

            const id = this.howl.play();
            this.howl.volume(volumeSetting * playVolume, id);
            return id;
        }, NaN);
    }

    pause(soundID?: number) {
        return captureAudioErrorsWithoutReturn(() => {
            if (this.howl === null)
                throw new Error("Audio hasn't started loading");
            if (!this.isLoaded() || !this.isPlaying(soundID))
                return;

            this.howl.pause(soundID);
        });
    }

    fade(
        startVolumeMul: number,
        endVolumeMul: number,
        durationMS: Milliseconds,
        soundID?: number,
    ) {
        captureAudioErrorsWithoutReturn(() => {
            if (this.howl === null)
                throw new Error("Audio hasn't started loading");
            if (soundID !== undefined && Number.isNaN(soundID))
                return;

            const vol = this.getVolumeSetting();
            this.howl.fade(vol * startVolumeMul, vol * endVolumeMul, durationMS, soundID);
        });
    }

    stop(soundID?: number) {
        captureAudioErrorsWithoutReturn(() => {
            if (this.howl === null || Number.isNaN(soundID))
                return;

            this.howl.stop(soundID);
        });
    }

    private onError(event: unknown) {
        this.errorTimeMs = getTimeMs();
        console.error("Error loading " + JSON.stringify(this.sources) + ": " + JSON.stringify(event));
    }
}
