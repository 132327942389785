import { GameSource } from "@/ts/business/game/controller/source/GameSource";
import { MoveGameEvent } from "@/ts/business/game/event/MoveGameEvent";
import { RollGameEvent } from "@/ts/business/game/event/RollGameEvent";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { Connection } from "@/ts/business/game/server/Connection";
import { PacketOutRoll } from "@/ts/business/game/server/outbound/PacketOutRoll";
import { PacketOutMove } from "@/ts/business/game/server/outbound/PacketOutMove";
import { PacketOutJoinGame } from "@/ts/business/game/server/outbound/PacketOutJoinGame";
import { PacketIn } from "@/ts/business/game/server/inbound/PacketIn";
import { PacketInLobbyStatus } from "@/ts/business/game/server/inbound/PacketInLobbyStatus";
import { PacketInGame } from "@/ts/business/game/server/inbound/PacketInGame";
import { GameUpdateEvent } from "@/ts/business/game/controller/source/GameUpdateEvent";
import { PacketInError } from "@/ts/business/game/server/inbound/PacketInError";
import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { PacketInUnknownLobby } from "@/ts/business/game/server/inbound/PacketInUnknownLobby";
import {
    LobbySettings,
} from "@/ts/business/game/LobbySettings";
import { ActionGameState } from "@/ts/royalur/rules/state/ActionGameState";
import { GameType } from "@/ts/business/game/GameType";
import { AnalyticsProvider } from "@/ts/business/analytics/Analytics";
import { ApproximateLobbySettings } from "@/ts/business/game/ApproximateLobbySettings";
import { MessageOutUploadGame } from "@/ts/business/api/game/MessageOutUploadGame";
import { GameAPI } from "@/ts/business/api/GameAPI";
import { ClientError } from "@/ts/business/game/error/ClientError";
import { Optional } from "@/ts/util/Optional";
import { APIGamePreferences } from "@/ts/business/api/api_schema";
import { GameClientControls } from "@/ts/business/GameClientControls";
import { PacketOutRematch } from "@/ts/business/game/server/outbound/PacketOutRematch";
import { PacketInJoinLobby } from "@/ts/business/game/server/inbound/PacketInJoinLobby";
import { PacketInReaction } from "@/ts/business/game/server/inbound/PacketInReaction";
import { ReactionType } from "@/ts/business/game/ReactionType";
import { PacketOutReaction } from "@/ts/business/game/server/outbound/PacketOutReaction";
import { PacketOutRefreshUser } from "@/ts/business/game/server/outbound/PacketOutRefreshUser";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { PacketOutResign } from "@/ts/business/game/server/outbound/PacketOutResign";
import { RemoteLobbySettings } from "@/ts/business/game/RemoteLobbySettings";
import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { TimeControlState } from "@/ts/business/lobby/TimeControlState";
import { PacketInPlayerData } from "@/ts/business/game/server/inbound/PacketInPlayerData";
import { GamePlayers } from "@/ts/business/api/game/GamePlayers";
import { RemoteLobbyStatus } from "@/ts/business/game/controller/status/RemoteLobbyStatus";
import { getTimeMs } from "@/ts/util/utils";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class OnlineGameSource extends GameSource<RemoteLobbyStatus> {
    private readonly lobbyID: LobbyID;
    private readonly name: string;
    private connection: Connection | null = null;
    private everReceivedGame: boolean = false;
    private receivedGame: boolean = false;
    private connectTime: number = 0;

    constructor(
        initialClientControls: GameClientControls,
        initialPreferences: APIGamePreferences,
        analytics: AnalyticsProvider,
        gameAPI: GameAPI,
        setupSettings: LobbySettings,
        lobbyID: LobbyID,
        name: string,
    ) {
        super(
            initialClientControls, initialPreferences, analytics, gameAPI, setupSettings,
            RemoteLobbyStatus.createDefault(lobbyID),
        );
        if (!lobbyID.getSource().isLiveLobbyID())
            throw new Error("Online games require a live lobby ID");

        this.lobbyID = lobbyID;
        this.name = name;
    }

    getApproximateGameSetupSettings(): ApproximateLobbySettings {
        const status = this.lobbyStatus.get();
        const mode = status.getGameMode();
        const botType = status.getBotType();
        if (mode === null)
            return new ApproximateLobbySettings(null, null, null, null);

        const game = this.game.get();
        const gameSettings = game.getRules().getSettings();
        const gameType = GameType.getBySettings(gameSettings);
        return new ApproximateLobbySettings(mode, gameType, gameSettings, botType);
    }

    override getKnownSetupSettings(): KnownLobbySettings | null {
        return this.getApproximateGameSetupSettings().toKnown();
    }

    override getControllerMaxDirectives(): number {
        return 25;
    }

    getConnection(): Connection {
        if (this.connection === null)
            throw new Error("The game source is not setup yet");

        return this.connection;
    }

    trySend(packet: PacketOut) {
        return this.getConnection().trySend(packet);
    }

    getLobbyID(): LobbyID {
        return this.lobbyID;
    }

    getHumanPlayer(): PlayerType | null {
        return this.players.get().getYourPlayer();
    }

    override isLocalHumanPlayer(playerType: PlayerType): boolean {
        return playerType === this.getHumanPlayer();
    }

    override updatePreferences(preferences: APIGamePreferences): boolean {
        const updated = super.updatePreferences(preferences);
        if (!updated)
            return updated;

        const players = this.players.get();
        if (players.getYourPlayer() === null)
            return updated;

        this.trySend(new PacketOutRefreshUser());
        return updated;
    }

    updateRematch(request: boolean): void {
        const players = this.players.get();
        const yourPlayerNo = players.getYourPlayerNo();

        const packet = new PacketOutRematch(request ? "request" : "cancel");
        if (!this.trySend(packet))
            return;

        this.lobbyStatus.map(status => status.withRematchRequestedBy(
            request ? yourPlayerNo : null,
        ));
    }

    override handleRematch(): void {
        const lobbyStatus = this.lobbyStatus.get();
        const players = this.players.get();
        const rematchRequestedBy = lobbyStatus.getRematchRequestedBy();
        const yourPlayerNo = players.getYourPlayerNo();
        const youRequestedRematch = (
            rematchRequestedBy !== null && rematchRequestedBy === yourPlayerNo
        );
        this.updateRematch(!youRequestedRematch);
    }

    override cancelRematch(): void {
        this.updateRematch(false);
    }

    override handleResign(): void {
        const player = this.players.get().getYourPlayer();
        if (!player)
            throw new Error("Own player is not known");

        const packet = new PacketOutResign();
        if (!this.trySend(packet))
            return;

        const newGame = this.game.get().copy();
        newGame.resign(player);

        // We don't want to send the game update event
        // until we've updated the lobby status as well.
        const sendGameEvent = this.game.setAndDeferEvent(
            newGame, new GameUpdateEvent(false),
        );

        this.lobbyStatus.map(status => status.withEnd(GameEndReason.RESIGNATION, player));
        sendGameEvent();
    }

    override setup(): () => void {
        const cleanup: (() => void)[] = [];

        const [endpoint, url] = this.getGameAPI().getLobbyWebSocketEndpointAndURL(
            this.lobbyID.get()
        );
        const connection = new Connection(endpoint, url, this.name);
        this.connection = connection;

        cleanup.push(connection.addOpenListener(
            () => this.handleOpenConnection(),
        ));
        cleanup.push(connection.addCloseListener(
            () => this.handleCloseConnection(),
        ));
        cleanup.push(connection.addPacketListener(
            packet => this.handlePacket(packet),
        ));
        cleanup.push(connection.setup());

        return () => {
            for (const cleanupFn of cleanup) {
                cleanupFn();
            }
            this.connection = null;
        };
    }

    override handleMove(event: MoveGameEvent): void {
        if (!this.lobbyStatus.get().isConnected())
            return;

        const game = this.game.get();
        if (game.isWaitingForMove() && game.getTurn() === this.getHumanPlayer()) {
            this.trySend(new PacketOutMove(event.getMove().getSourceOrNull()));
            game.move(event.getMove());
            this.game.set(game, new GameUpdateEvent(event.getSkipAnimation()));

            if (game.isFinished()) {
                const settings = this.getApproximateGameSetupSettings();
                if (settings) {
                    this.getAnalytics().recordFinishGame(settings);
                }
            }
        }
    }

    override handleRoll(_event: RollGameEvent): void {
        if (!this.lobbyStatus.get().isConnected())
            return;

        const game = this.game.get();
        if (game.isWaitingForRoll() && game.getTurn() === this.getHumanPlayer()) {
            this.trySend(new PacketOutRoll());
            this.triggerDiceRollingAnimation();
        }
    }

    handleReaction(player: PlayerType | null, reaction: ReactionType) {
        this.trySend(new PacketOutReaction(reaction));
        this.triggerReaction(player, reaction);
    }

    handleOpenConnection() {
        this.receivedGame = false;
        this.trySend(new PacketOutJoinGame(false));
        console.log("open");

        this.connectTime = getTimeMs();
        this.lobbyStatus.map(status => status.withConnected(true));
    }

    handleCloseConnection() {
        this.receivedGame = false;
        console.log("close");

        const players = this.players.get();
        const yourPlayer = players.getYourPlayer();
        let newLobbyStatus = this.lobbyStatus.get();
        if (yourPlayer !== null) {
            const data = players.getPlayerDataByType(yourPlayer);
            newLobbyStatus = newLobbyStatus.withPlayerConnected(data.getPlayerNo(), false);
        }
        this.lobbyStatus.set(newLobbyStatus.withConnected(false));
    }

    correctServerTime(serverTimeMs: number): number {
        if (!this.connection || serverTimeMs === 0)
            return serverTimeMs;

        return this.connection.correctServerTime(serverTimeMs);
    }

    handlePacket(packet: PacketIn) {
        if (packet instanceof PacketInLobbyStatus) {
            this.lobbyStatus.map(status => status.withGameInfo(
                packet.gameMode,
                packet.botType,
                packet.endReason,
                packet.endingPlayer,
                this.correctServerTime(packet.player1LastPingTimeMs ?? 0),
                this.correctServerTime(packet.player2LastPingTimeMs ?? 0),
                packet.finishedGameID,
                packet.rematchRequestedBy,
            ));

        } else if (packet instanceof PacketInPlayerData) {
            this.players.set(new GamePlayers(
                packet.yourPlayer ?? PlayerType.LIGHT,
                packet.yourPlayer,
                packet.getPlayer(PlayerType.LIGHT),
                packet.getPlayer(PlayerType.DARK),
            ));

        } else if (packet instanceof PacketInGame) {
            const previousGame = this.game.get();
            const previouslyReceivedGame = this.receivedGame;
            const previouslyEverReceivedGame = this.everReceivedGame;

            const game = packet.getGame();
            const rawTimeControlState = packet.getTimeControlState() ?? TimeControlState.EMPTY;
            const timeControlState = new TimeControlState(
                this.correctServerTime(rawTimeControlState.rollExpiryTimeMs),
                this.correctServerTime(rawTimeControlState.moveExpiryTimeMs),
                this.correctServerTime(rawTimeControlState.gameAbandonTimeMs),
            );
            const players = this.players.get();
            const yourPlayer = players.getYourPlayer();

            let anyPlayerActions = false;
            for (const state of game.getStates()) {
                if (state instanceof ActionGameState
                    && (yourPlayer === null || state.getTurn() === yourPlayer)) {

                    anyPlayerActions = true;
                }
            }

            const gameIsHidden = (document.visibilityState === "hidden");
            const skipAnimation = ((!this.receivedGame && anyPlayerActions) || gameIsHidden);
            this.updateGameAndTimeControlState(
                game, timeControlState, new GameUpdateEvent(skipAnimation)
            );
            this.everReceivedGame = true;
            this.receivedGame = true;

            this.lobbyStatus.map(status => status.withGameAvailable(true));

            if (previouslyReceivedGame && !previousGame.isFinished() && game.isFinished()) {
                const settings = this.getApproximateGameSetupSettings();
                if (settings) {
                    this.getAnalytics().recordFinishGame(settings);
                }
            } else if (!previouslyEverReceivedGame) {
                // A dodgy way to avoid recording a start game when a
                // player reloads their page. However, if they restart
                // when no rolls or moves have been made, this will
                // still record the start game twice... oh well.
                const settings = this.getApproximateGameSetupSettings();
                if (!anyPlayerActions && settings) {
                    this.getAnalytics().recordStartGame(settings);

                    if (getTimeMs() - this.connectTime > 1000) {
                        let body = "Your online game has started!";
                        const oppPlayer = (yourPlayer !== null ? yourPlayer.getOtherPlayer() : null);
                        if (oppPlayer) {
                            const opp = players.getPlayerByType(oppPlayer);
                            if (!opp.isAnonymous()) {
                                body = `Your game vs. ${opp.getLongName()} has started!`;
                            }
                        }

                        this.clientControls.get().notify(
                            "Game Started!", body
                        );
                    }
                }
            }

        } else if (packet instanceof PacketInReaction) {
            const playerNo = packet.playerNo;
            let playerType: PlayerType | null = null;
            if (playerNo !== null) {
                const players = this.players.get();
                playerType = players.getPlayerByNo(playerNo).getPlayerType();
            }
            if (packet.reaction) {
                this.triggerReaction(playerType, packet.reaction);
            }

        } else if (packet instanceof PacketInError) {
            console.error("Received Error Packet:", packet.message);
            this.error.set(Optional.item(
                new ClientError(packet.getData(), packet.getMessage()),
            ));

        } else if (packet instanceof PacketInUnknownLobby) {
            this.lobbyStatus.map(status => status.withGameUnknown(true));
            this.getConnection().disable();

        } else if (packet instanceof PacketInJoinLobby) {
            if (packet.lobbyID === null)
                throw new Error("Join lobby packet is missing its lobbyID");

            const clientControls = this.clientControls.get();
            clientControls.startGame(new RemoteLobbySettings(packet.lobbyID));

        } else {
            throw new Error("Unknown packet " + packet.type.getName());
        }
    }

    override shouldUploadGame(): boolean {
        return false;
    }

    override buildUploadGameMessage(): MessageOutUploadGame {
        throw new Error("Online games should not be manually uploaded");
    }
}
