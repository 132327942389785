import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutRefreshUser extends PacketOut {

    constructor() {
        super(OutboundPacketType.REFRESH_USER);
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        // Nothing to write.
        return {};
    }
}
