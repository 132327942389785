import { MutableRefObject, useEffect, useRef } from "react";
import { getTimeSeconds } from "@/ts/util/utils";


export abstract class CanvasRenderer {
    /**
     * Called to attach listeners or other callbacks.
     * This should return a cleanup function.
     */
    abstract attachListeners(): () => void;

    calculateRenderBounds(bounds: CanvasBounds): CanvasBounds {
        return bounds;
    }

    clearCanvas(
        ctx: CanvasRenderingContext2D,
        width: number,
        height: number,
    ) {
        ctx.clearRect(0, 0, width, height);
    }

    abstract render(
        ctx: CanvasRenderingContext2D,
        width: number,
        height: number,
        dt: number,
    ): void;
}

export class CanvasBounds {
    readonly rect: DOMRect | null;
    readonly width: number;
    readonly height: number;

    private uses: number;

    constructor(rect: DOMRect | null, width: number, height: number) {
        this.rect = rect;
        this.width = width;
        this.height = height;
        this.uses = 0;
    }

    addUse(): number {
        this.uses += 1;
        return this.uses;
    }

    close(other: CanvasBounds) {
        return Math.abs(this.width - other.width) <= 50
            && Math.abs(this.height - other.height) <= 50;
    }

    equals(other: CanvasBounds) {
        return this.width === other.width
            && this.height === other.height;
    }

    isOnScreen(): boolean {
        if (this.rect === null)
            return false;

        const screenWidth = window.innerWidth || document.documentElement.clientWidth;
        const screenHeight = window.innerHeight || document.documentElement.clientHeight;
        if (!screenWidth || !screenHeight)
            return true;

        return (
            this.rect.bottom >= 0
            && this.rect.right >= 0
            && this.rect.top <= screenHeight
            && this.rect.left <= screenWidth
        );
    }

    static get(element: HTMLCanvasElement) {
        const rect = element.getBoundingClientRect();
        const width = Math.ceil(rect.width * window.devicePixelRatio);
        const height = Math.ceil(rect.height * window.devicePixelRatio);
        return new CanvasBounds(
            rect,
            Math.min(5000, Math.max(50, width)),
            Math.min(5000, Math.max(50, height)),
        );
    }
}

interface RenderedCanvasProps {
    className?: string;
    renderer: CanvasRenderer;
    updateEveryFrame: boolean;
    canvasRef: MutableRefObject<HTMLCanvasElement | null>;
    rotateLeft90?: boolean;
}


export function RenderedCanvas({
    className, renderer, updateEveryFrame, canvasRef, rotateLeft90,
}: RenderedCanvasProps) {
    const previousBoundsRef = useRef<CanvasBounds>(new CanvasBounds(null, -1, -1));

    useEffect(() => {
        return renderer.attachListeners();
    }, [renderer]);

    useEffect(() => {
        const updateLoopState: {
            animationFrameID: number | null;
            lastRenderTime: number;
            stopped: boolean;
        } = {
            animationFrameID: null,
            lastRenderTime: getTimeSeconds(),
            stopped: false,
        };

        const update = () => {
            if (updateLoopState.stopped)
                return;

            const canvas = canvasRef.current;
            if (!canvas)
                return;

            // Get the rendering context.
            const ctx = canvas.getContext("2d");
            if (!ctx)
                return;

            ctx.imageSmoothingEnabled = true;
            ctx.imageSmoothingQuality = "high";

            const time = getTimeSeconds();
            try {
                // Make sure the width/height of the canvas is correct.
                const currentBounds = renderer.calculateRenderBounds(
                    CanvasBounds.get(canvas),
                );

                const previousBounds = previousBoundsRef.current;
                const uses = previousBounds.addUse();

                let bounds = previousBounds;
                if (!currentBounds.equals(previousBounds)
                    && (!currentBounds.close(previousBounds) || uses >= 60)) {

                    previousBoundsRef.current = currentBounds;
                    canvas.width = currentBounds.width;
                    canvas.height = currentBounds.height;
                    bounds = currentBounds;
                }

                // Render!
                if (currentBounds.isOnScreen()) {
                    const dt = time - updateLoopState.lastRenderTime;
                    updateLoopState.lastRenderTime = time;

                    ctx.save();
                    let width = bounds.width;
                    let height = bounds.height;

                    if (rotateLeft90) {
                        ctx.translate(width / 2, height / 2);
                        ctx.rotate(Math.PI / 2);
                        ctx.scale(1, -1);
                        ctx.translate(-height / 2, -width / 2);

                        // Variable juggling to avoid warning...
                        const bounds1 = height;
                        const bounds2 = width;
                        width = bounds1;
                        height = bounds2;
                    }

                    renderer.render(ctx, width, height, dt);
                    ctx.restore();
                }
            } finally {
                if (updateEveryFrame) {
                    updateLoopState.animationFrameID = requestAnimationFrame(update);
                }
            }
        };

        updateLoopState.animationFrameID = requestAnimationFrame(update);
        return () => {
            updateLoopState.stopped = true;
            if (updateLoopState.animationFrameID !== null) {
                cancelAnimationFrame(updateLoopState.animationFrameID);
            }
        };
    }, [canvasRef, renderer, updateEveryFrame, rotateLeft90]);

    return (
        <canvas ref={canvasRef} className={className} />
    );
}
