import { BotType } from "@/ts/business/game/BotType";
import { Game } from "@/ts/royalur/Game";
import { Agent } from "@/ts/royalur/agent/Agent";
import { getJsonType, isJsonDict, readJsonDict, readJsonInt } from "@/ts/util/json";
import { Move } from "@/ts/royalur/model/Move";
import { RoyalUrJsonNotation } from "@/ts/business/game/royalur/RoyalUrJsonNotation";


class Message {
    public readonly messageID: number;
    public readonly readResponse: (json: Record<string, any>) => void;
    public readonly acceptError: (reason: any) => void;

    constructor(
        messageID: number,
        readResponse: (json: Record<string, any>) => void,
        acceptError: (reason: any) => void,
    ) {
        this.messageID = messageID;
        this.readResponse = readResponse;
        this.acceptError = acceptError;
    }
}


export class AgentThread extends Agent {
    private readonly worker: Worker;
    private readonly notation: RoyalUrJsonNotation;

    private readonly messages: Message[] = [];
    private nextMessageID: number;

    constructor(botType: BotType) {
        super();
        this.worker = new Worker(new URL("./agent.worker.ts", import.meta.url));
        this.notation = new RoyalUrJsonNotation();
        this.nextMessageID = 1;

        this.worker.addEventListener("message", (event: MessageEvent) => {
            this.onMessageResponse(event);
        });

        this.postMessage(
            "setup",
            { bot_type: botType.getID() },
            () => {},
            (reason: any) => { throw reason; },
        );
    }

    postMessage(
        type: string,
        json: Record<string, any>,
        readResponse: (json: Record<string, any>) => void,
        catchError: (reason: any) => void,
    ) {
        const messageID = ++this.nextMessageID;
        const message = new Message(
            messageID, readResponse, catchError,
        );
        this.messages.push(message);

        this.worker.postMessage({
            type: type,
            messageID: messageID,
            content: json,
        });
    }

    onMessageResponse(event: MessageEvent) {
        const json = event.data;
        if (!isJsonDict(json))
            throw new Error(`Expected a dictionary, not a ${getJsonType(json)}`);

        const messageID = readJsonInt(json, "messageID");
        const content = readJsonDict(json, "content");
        const messageIndex = this.findMessageIndex(messageID);
        const [message] = this.messages.splice(messageIndex, 1);
        message.readResponse(content);
    }

    findMessageIndex(messageID: number): number {
        for (let index = 0; index < this.messages.length; ++index) {
            const message = this.messages[index];
            if (message.messageID === messageID)
                return index;
        }
        throw new Error(`Unable to find message with ID ${messageID}`);
    }

    override playTurn(
        game: Game,
    ): Promise<Game> {
        if (game.isFinished())
            throw new Error("The game has already been completed");
        if (!game.isPlayable())
            throw new Error("The game is not in a playable state");

        // Just roll the dice, there's not any decisions to be made here.
        if (game.isWaitingForRoll()) {
            const newGame = game.copy();
            newGame.rollDice();
            return Promise.resolve(newGame);
        }

        // If there is only one available move, make it.
        if (game.isWaitingForMove()) {
            const availableMoves = game.findAvailableMoves();
            if (availableMoves.length === 1) {
                const newGame = game.copy();
                newGame.move(availableMoves[0]);
                return Promise.resolve(newGame);
            }
        }

        // Defer to the search AI.
        const gameJson = this.notation.writeGame(game);
        return new Promise<Game>(
            (resolve, reject) => {
                this.postMessage(
                    "playTurn",
                    gameJson,
                    (response: Record<string, any>) => {
                        resolve(this.notation.readGame(response));
                    },
                    reject,
                );
            },
        );
    }

    override decideMove(
        _game: Game,
        _availableMoves: Move[],
    ): Move {
        throw new Error("decideMove is unimplemented for AgentThread");
    }
}
