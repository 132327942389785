import { Vec2 } from "@/ts/util/Vec2";


export class Rectangle {
    readonly left: number;
    readonly top: number;
    readonly right: number;
    readonly bottom: number;

    constructor(left: number, top: number, right: number, bottom: number) {
        if (left > right)
            throw new Error("left must not be greater than right: " + left + " > " + right);
        if (top > bottom)
            throw new Error("top must not be greater than bottom: " + top + " > " + bottom);

        this.left = left;
        this.top = top;
        this.right = right;
        this.bottom = bottom;
    }

    getWidth(): number {
        return this.right - this.left;
    }

    getHeight(): number {
        return this.bottom - this.top;
    }

    getCentre(): Vec2 {
        return new Vec2(
            (this.left + this.right) / 2,
            (this.top + this.bottom) / 2,
        );
    }

    equals(other: Rectangle) {
        return this.left === other.left
            && this.top === other.top
            && this.right === other.right
            && this.bottom === other.bottom;
    }

    /**
     * Calculates the smallest rectangle that
     * covers all locations provided.
     */
    static covering(locs: Vec2[]): Rectangle {
        if (locs.length === 0)
            throw new Error("No locations provided");

        const first = locs[0];
        let minX = first.x;
        let maxX = first.x;
        let minY = first.y;
        let maxY = first.y;
        for (let index = 1; index < locs.length; ++index) {
            const loc = locs[index];
            minX = Math.min(minX, loc.x);
            maxX = Math.max(maxX, loc.x);
            minY = Math.min(minY, loc.y);
            maxY = Math.max(maxY, loc.y);
        }
        return new Rectangle(
            minX, minY, maxX, maxY,
        );
    }
}
