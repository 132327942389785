import React from "react";


export function areDependenciesEqual(deps1: React.DependencyList, deps2: React.DependencyList) {
    if (deps1.length !== deps2.length)
        return false;

    for (let i = 0; i < deps1.length; i++) {
        if (deps1[i] !== deps2[i])
            return false;
    }
    return true;
}
