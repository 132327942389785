import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export type RematchPacketMode = "request" | "cancel";


export class PacketOutRematch extends PacketOut {

    private readonly mode: RematchPacketMode;

    constructor(mode: RematchPacketMode) {
        super(OutboundPacketType.REMATCH);
        this.mode = mode;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            mode: this.mode,
        };
    }
}
