import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { GamePlayerData } from "@/ts/business/api/game/GamePlayerData";
import { LobbyPlayer } from "@/ts/business/lobby/LobbyPlayer";
import { PlayerNo } from "@/ts/business/game/PlayerNo";
import { HumanLobbyPlayer } from "@/ts/business/lobby/HumanLobbyPlayer";
import { APIPublicGamePreferences } from "@/ts/business/api/api_schema";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";


export class GamePlayers {
    private readonly leftPlayer: PlayerType;
    private readonly yourPlayer: PlayerType | null;
    private readonly lightPlayer: LobbyPlayer;
    private readonly darkPlayer: LobbyPlayer;
    private readonly lightPlayerData: GamePlayerData;
    private readonly darkPlayerData: GamePlayerData;

    constructor(
        leftPlayer: PlayerType,
        yourPlayer: PlayerType | null,
        lightPlayer: LobbyPlayer,
        darkPlayer: LobbyPlayer,
    ) {
        this.leftPlayer = leftPlayer;
        this.yourPlayer = yourPlayer;
        this.lightPlayer = lightPlayer;
        this.darkPlayer = darkPlayer;

        const lightPlayerData = lightPlayer.getGameData();
        const darkPlayerData = darkPlayer.getGameData();
        if (lightPlayerData === null)
            throw new Error("lightPlayer data cannot be missing");
        if (darkPlayerData === null)
            throw new Error("lightPlayer data cannot be missing");

        this.lightPlayerData = lightPlayerData;
        this.darkPlayerData = darkPlayerData;
    }

    withPlayer(player: LobbyPlayer) {
        const data = player.getGameData();
        if (data === null)
            throw new Error("player data cannot be missing");

        let lightPlayer = this.lightPlayer;
        let darkPlayer = this.darkPlayer;
        if (data.getPlayerType() === PlayerType.LIGHT) {
            lightPlayer = player;
        } else {
            darkPlayer = player;
        }
        return new GamePlayers(
            this.leftPlayer, this.yourPlayer,
            lightPlayer, darkPlayer,
        );
    }

    areReactionsDisabled(userPreferences?: APIPublicGamePreferences): boolean {
        if (this.lightPlayer instanceof HumanLobbyPlayer) {
            const lightPreferences = (
                this.yourPlayer === PlayerType.LIGHT
                    ? userPreferences : this.lightPlayer.getPreferences()
            );
            if (lightPreferences?.disabledReactions)
                return true;
        }
        if (this.darkPlayer instanceof HumanLobbyPlayer) {
            const darkPreferences = (
                this.yourPlayer === PlayerType.DARK
                    ? userPreferences : this.darkPlayer.getPreferences()
            );
            if (darkPreferences?.disabledReactions)
                return true;
        }
        return false;
    }

    getLeftPlayer(): PlayerType {
        return this.leftPlayer;
    }

    getRightPlayer(): PlayerType {
        return this.leftPlayer.getOtherPlayer();
    }

    getYourPlayer(): PlayerType | null {
        return this.yourPlayer;
    }

    getYourPlayerNo(): PlayerNo | null {
        if (this.yourPlayer === null)
            return null;

        return this.getPlayerDataByType(this.yourPlayer).getPlayerNo();
    }

    isSpectator(status: LobbyStatus): boolean {
        const gameMode = status.getGameMode();
        const botType = status.getBotType();
        if (!gameMode?.isRemote() && !botType?.isOnline())
            return false;

        return this.yourPlayer === null;
    }

    getPlayerByNo(playerNo: number): LobbyPlayer {
        if (this.lightPlayerData.getPlayerNo() === playerNo)
            return this.lightPlayer;
        if (this.darkPlayerData.getPlayerNo() === playerNo)
            return this.darkPlayer;
        throw new Error(`Missing player no ${playerNo}`);
    }

    getPlayer1(): LobbyPlayer {
        return this.getPlayerByNo(1);
    }

    getPlayer2(): LobbyPlayer {
        return this.getPlayerByNo(2);
    }

    getPlayerDataByNo(playerNo: number): GamePlayerData {
        if (this.lightPlayerData.getPlayerNo() === playerNo)
            return this.lightPlayerData;
        if (this.darkPlayerData.getPlayerNo() === playerNo)
            return this.darkPlayerData;
        throw new Error(`Missing player no ${playerNo}`);
    }

    getPlayer1Data(): GamePlayerData {
        return this.getPlayerDataByNo(1);
    }

    getPlayer2Data(): GamePlayerData {
        return this.getPlayerDataByNo(2);
    }

    getPlayerByType(player: PlayerType): LobbyPlayer {
        return player === PlayerType.LIGHT ? this.lightPlayer : this.darkPlayer;
    }

    getLightPlayer(): LobbyPlayer {
        return this.lightPlayer;
    }

    getDarkPlayer(): LobbyPlayer {
        return this.darkPlayer;
    }

    getPlayerDataByType(player: PlayerType): GamePlayerData {
        return player === PlayerType.LIGHT ? this.lightPlayerData : this.darkPlayerData;
    }

    getLightPlayerData(): GamePlayerData {
        return this.lightPlayerData;
    }

    getDarkPlayerData(): GamePlayerData {
        return this.darkPlayerData;
    }

    equals(other: GamePlayers): boolean {
        return this.leftPlayer === other.leftPlayer
            && this.yourPlayer === other.yourPlayer
            && this.lightPlayer.equals(other.lightPlayer)
            && this.darkPlayer.equals(other.darkPlayer);
    }
}
