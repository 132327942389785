import { randID } from "@/ts/util/random";
import { LobbyIDSource } from "@/ts/business/lobby/LobbyIDSource";


export class LobbyID {
    private readonly source: LobbyIDSource;
    private readonly value: string;

    private constructor(source: LobbyIDSource, value: string) {
        this.source = source;
        this.value = value;
    }

    getSource(): LobbyIDSource {
        return this.source;
    }

    get(): string {
        return this.value;
    }

    static ofLiveLobby(lobbyID: string): LobbyID {
        return new LobbyID(LobbyIDSource.LIVE_LOBBY, lobbyID);
    }

    static ofCompleteGame(lobbyID: string): LobbyID {
        return new LobbyID(LobbyIDSource.COMPLETE_GAME, lobbyID);
    }

    static generateRandom(): LobbyID {
        return new LobbyID(LobbyIDSource.USER_GENERATED, randID());
    }
}
