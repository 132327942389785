import { PlayerState } from "@/ts/royalur/model/PlayerState";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerStateDirective } from "@/ts/business/game/controller/playerstate/PlayerStateDirective";


/**
 * A directive for rendering the state of the player.
 */
export class BasicPlayerStateDirective extends PlayerStateDirective {
    readonly lightPlayerState: PlayerState;
    readonly darkPlayerState: PlayerState;
    readonly activePlayer: PlayerType;

    constructor(
        directiveID: number,
        moveIndex: number,
        lightPlayerState: PlayerState,
        darkPlayerState: PlayerState,
        activePlayer: PlayerType,
    ) {
        super(directiveID, moveIndex);
        this.lightPlayerState = lightPlayerState;
        this.darkPlayerState = darkPlayerState;
        this.activePlayer = activePlayer;
    }

    override getActivePlayer(_moveIndex: number): PlayerType {
        return this.activePlayer;
    }

    override getLightPlayerState(_moveIndex: number): PlayerState {
        return this.lightPlayerState;
    }

    override getDarkPlayerState(_moveIndex: number): PlayerState {
        return this.darkPlayerState;
    }
}
