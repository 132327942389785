import { createContext, ReactNode, useContext, useRef } from "react";
import { GameTheme } from "@/app_components/game/theme/GameTheme";
import { GameThemeType } from "@/ts/business/game/theme/GameThemeType";
import { useAssetLoader } from "@/app_components/assets/AssetContext";


const GameThemeContext = createContext<GameTheme | null>(null);

export function useGameTheme(): GameTheme {
    const gameTheme = useContext(GameThemeContext);
    if (gameTheme === null)
        throw new Error("Missing game theme!");

    return gameTheme;
}


interface GameThemeProviderProps {
    children: ReactNode;
    themeType: GameThemeType;
}

export function GameThemeProvider({ children, themeType }: GameThemeProviderProps) {
    const assetLoader = useAssetLoader();
    const themeRef = useRef<GameTheme | null>(null);
    if (themeRef.current === null || themeRef.current.getThemeType() != themeType) {
        themeRef.current = GameTheme.create(themeType, assetLoader);
    }
    return (
        <GameThemeContext.Provider value={themeRef.current}>
            {children}
        </GameThemeContext.Provider>
    );
}
