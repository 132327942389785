import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getGamePreferences } from "@/app/(api)/api/getGamePreferences/getGamePreferences";
import { APIGamePreferences, APIUser, defaultGamePreferences } from "@/ts/business/api/api_schema";
import { createContext, ReactNode, startTransition, useCallback, useContext, useMemo, useState } from "react";
import { Rune } from "@/ts/business/Rune";
import { useRune } from "@/app_util/useRune";


interface DefaultGamePreferences {
    userPublicID: string | null;
    preferences: APIGamePreferences;
}


const DefaultGamePreferencesContext = createContext<DefaultGamePreferences | null>(null);


interface DefaultGamePreferencesProviderProps {
    user: APIUser | null;
    defaultPreferences: APIGamePreferences | null;
    children?: ReactNode;
}


export function DefaultGamePreferencesProvider({
    user, defaultPreferences, children,
}: DefaultGamePreferencesProviderProps) {

    let pref: DefaultGamePreferences | null = null;
    if (user && defaultPreferences) {
        pref = {
            userPublicID: user.publicID,
            preferences: defaultPreferences,
        };
    }

    return (
        <DefaultGamePreferencesContext.Provider value={pref}>
            {children}
        </DefaultGamePreferencesContext.Provider>
    );
}


const savedPreferencesRune = new Rune<APIGamePreferences>(defaultGamePreferences);


export function useGamePreferences(user: APIUser | null): {
    preferences: APIGamePreferences;
    preferencesLoading: boolean;
    preferencesError: Error | null;
    setPreferences: (preferences: APIGamePreferences) => void;
    savedPreferences: APIGamePreferences | null;
    setSavedPreferences: (preferences: APIGamePreferences) => void;
} {
    const queryClient = useQueryClient();
    const serverDefaultPref = useContext(DefaultGamePreferencesContext);
    const [defaultPref, setDefaultPref] = useState<APIGamePreferences>(
        serverDefaultPref && serverDefaultPref.userPublicID === user?.publicID
            ? serverDefaultPref.preferences
            : defaultGamePreferences,
    );
    const savedPreferences = useRune(savedPreferencesRune);

    const queryKey = useMemo(() => {
        return ["gamePreferences", user?.publicID ?? ""];
    }, [user?.publicID]);

    const { data, isLoading, error } = useQuery({
        queryKey: queryKey,
        queryFn: async () => {
            // If the user isn't signed in, then they
            // won't have any preferences to fetch.
            if (!user) {
                savedPreferencesRune.set(defaultPref);
                return defaultPref;
            }

            const pref = await getGamePreferences();
            savedPreferencesRune.set(pref);
            return pref;
        },
        staleTime: 60 * 60 * 1000,
        refetchInterval: 60 * 60 * 1000,
    });

    const setPreferences = useCallback((pref: APIGamePreferences) => {
        startTransition(() => {
            setDefaultPref(pref);
            queryClient.setQueryData(queryKey, pref);
        });
    }, [queryKey, queryClient]);

    const setSavedPreferences = useCallback((pref: APIGamePreferences) => {
        startTransition(() => {
            setPreferences(pref);
            savedPreferencesRune.set(pref);
        });
    }, [setPreferences]);

    return {
        preferences: data ?? defaultPref,
        preferencesLoading: isLoading,
        preferencesError: error,
        setPreferences,
        savedPreferences,
        setSavedPreferences,
    };
}
