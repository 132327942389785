

export class LobbyIDSource {
    public static readonly LIVE_LOBBY = new LobbyIDSource(
        "live_lobby", true, false, false
    );

    public static readonly USER_GENERATED = new LobbyIDSource(
        "user_generated", false, true, false
    );

    public static readonly COMPLETE_GAME = new LobbyIDSource(
        "complete_game", false, false, true
    );

    static values(): LobbyIDSource[] {
        return [
            LobbyIDSource.LIVE_LOBBY,
            LobbyIDSource.USER_GENERATED,
            LobbyIDSource.COMPLETE_GAME,
        ];
    }

    private readonly id: string;
    private readonly isLive: boolean;
    private readonly isUG: boolean;
    private readonly isComplete: boolean;

    private constructor(
        id: string,
        isLive: boolean,
        isUG: boolean,
        isComplete: boolean,
    ) {
        this.id = id;
        this.isLive = isLive;
        this.isUG = isUG;
        this.isComplete = isComplete;
    }

    getID(): string {
        return this.id;
    }

    /**
     * Whether this ID refers to a live lobby.
     */
    isLiveLobbyID(): boolean {
        return this.isLive;
    }

    /**
     * Whether this ID was user-generated.
     */
    isUserGenerated(): boolean {
        return this.isUG;
    }

    /**
     * Whether this ID refers to a completed game.
     */
    isCompletedGameID(): boolean {
        return this.isComplete;
    }
}
