import { PacketOut } from "@/ts/business/game/server/outbound/PacketOut";
import { OutboundPacketType } from "@/ts/business/game/server/outbound/OutboundPacketType";
import { MessageIOContext } from "@/ts/business/api/MessageIOContext";


export class PacketOutPing extends PacketOut {
    private readonly clientTimeMs: number;

    constructor(clientTimeMs: number) {
        super(OutboundPacketType.PING);
        this.clientTimeMs = clientTimeMs;
    }

    override writeContents(_context: MessageIOContext): Record<string, any> {
        return {
            client_time_ms: this.clientTimeMs,
        };
    }
}
