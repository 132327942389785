import { ErrorType } from "@/ts/business/game/error/ErrorType";
import { ErrorData } from "@/ts/business/game/error/ErrorData";


export class ClientError extends Error {
    private readonly data: ErrorData | null;

    constructor(data: ErrorData | null, message: string) {
        super(message);
        this.data = data;
    }

    getErrorType(): ErrorType {
        return this.data?.getType() ?? ErrorType.UNKNOWN;
    }

    getData(): ErrorData | null {
        return this.data;
    }
}
