import { GameMode } from "@/ts/business/game/GameMode";
import { GameType } from "@/ts/business/game/GameType";
import { BotType } from "@/ts/business/game/BotType";

import { KnownLobbySettings } from "@/ts/business/game/KnownLobbySettings";
import { GameSettings } from "@/ts/royalur/model/GameSettings";


/**
 * Lobby settings that may not include all information.
 */
export class ApproximateLobbySettings {
    private readonly mode: GameMode | null;
    private readonly gameType: GameType | null;
    private readonly customGameSettings: GameSettings | null;
    private readonly botType: BotType | null;

    constructor(
        mode: GameMode | null,
        gameType: GameType | null,
        customGameSettings: GameSettings | null,
        botType: BotType | null,
    ) {
        this.mode = mode;
        this.gameType = gameType;
        this.customGameSettings = customGameSettings;
        this.botType = botType;
    }

    getMode(): GameMode | null {
        return this.mode;
    }

    getGameType(): GameType | null {
        if (this.gameType !== null)
            return this.gameType;
        if (this.customGameSettings !== null)
            return GameType.getBySettings(this.customGameSettings);
        return null;
    }

    getGameSettings(): GameSettings | null {
        if (this.gameType !== null && this.gameType.hasSettings())
            return this.gameType.getSettings();

        return this.customGameSettings;
    }

    getCustomGameSettings(): GameSettings | null {
        return this.customGameSettings;
    }

    getBotType(): BotType | null {
        return this.botType;
    }

    toKnown(): KnownLobbySettings | null {
        if (this.mode === null || this.gameType === null || this.customGameSettings === null)
            return null;

        return new KnownLobbySettings(
            this.mode,
            this.gameType,
            this.customGameSettings,
            this.botType ?? BotType.EASY,
        );
    }
}
