import { GameMode } from "@/ts/business/game/GameMode";
import { BotType } from "@/ts/business/game/BotType";
import { GameEndReason } from "@/ts/business/game/GameEndReason";
import { PlayerType } from "@/ts/royalur/model/PlayerType";
import { PlayerNo } from "@/ts/business/game/PlayerNo";
import { LobbyStatus } from "@/ts/business/game/controller/status/LobbyStatus";
import { RemoteLobbyStatus } from "@/ts/business/game/controller/status/RemoteLobbyStatus";
import { LobbyID } from "@/ts/business/lobby/LobbyID";


export class LocalLobbyStatus extends LobbyStatus {
    private readonly lobbyID: LobbyID;
    private readonly mode: GameMode;
    private readonly botType: BotType | null;
    private readonly endReason: GameEndReason | null;
    private readonly endingPlayer: PlayerType | null;
    private readonly completeGameID: string | null;

    constructor(
        lobbyID: LobbyID,
        mode: GameMode,
        botType: BotType | null,
        endReason: GameEndReason | null,
        endingPlayer: PlayerType | null,
        completeGameID: string | null,
    ) {
        super();
        this.lobbyID = lobbyID;
        this.mode = mode;
        this.botType = botType;
        this.endReason = endReason;
        this.endingPlayer = endingPlayer;
        this.completeGameID = completeGameID;
    }

    override isRemote(): this is RemoteLobbyStatus {
        return false;
    }

    override getLobbyID(): LobbyID {
        return this.lobbyID;
    }

    override hasEverConnected(): boolean {
        return true;
    }

    override isConnected(): boolean {
        return true;
    }

    override isGameAvailable(): boolean {
        return true;
    }

    override isGameUnknown(): boolean {
        return false;
    }

    override getGameMode(): GameMode {
        return this.mode;
    }

    override getBotType(): BotType | null {
        return this.botType;
    }

    override getEndReason(): GameEndReason | null {
        return this.endReason;
    }

    override getEndingPlayer(): PlayerType | null {
        return this.endingPlayer;
    }

    override hasPingTimes(): boolean {
        return false;
    }

    override getPlayer1LastPingTimeMs(): number {
        return 0;
    }

    override getPlayer2LastPingTimeMs(): number {
        return 0;
    }

    override getCompleteGameID(): string | null {
        return this.completeGameID;
    }

    override withCompleteGameID(completeGameID: string | null): LocalLobbyStatus {
        return new LocalLobbyStatus(
            this.lobbyID,
            this.mode,
            this.botType,
            this.endReason,
            this.endingPlayer,
            completeGameID,
        );
    }

    override withEnd(abandonReason: GameEndReason, abandoner: PlayerType): LocalLobbyStatus {
        return new LocalLobbyStatus(
            this.lobbyID,
            this.mode,
            this.botType,
            abandonReason,
            abandoner,
            this.completeGameID,
        );
    }

    override getRematchRequestedBy(): PlayerNo | null {
        return null;
    }
}
